import React from 'react';
import OrganizationNav from './OrganizationNav';
import OrganizationBreadcrumbs from './OrganizationBreadcrumbs';

export interface LayoutProps {
	children: React.ReactNode;
}

/**
 * Layout component for the Organization SPA
 */
export default function Layout(props: LayoutProps) {
	return (
		<>
			<OrganizationBreadcrumbs />
			<div className="roc-manage-layout__body">
				<div className="roc-manage-layout__body-wrapper">
					<OrganizationNav />
					<div className="roc-manage-layout__main">{props.children}</div>
				</div>
			</div>
		</>
	);
}
