import React from 'react';
import { SVGProps } from './SVGProps';
import classnames from 'classnames';

/**
 * VersaPay SVG
 *
 * @returns
 */
function VersaPaySVG({ className, ...rest }: SVGProps) {
	return (
		<svg
			className={classnames('roc-icon', className)}
			focusable="false"
			viewBox="0 0 170 170"
			aria-hidden="true"
			{...rest}
		>
			<circle cx="85" cy="85" r="85" fill="#000" />
			<path
				d="M102.849 43L84.9904 100.665L67.1418 43H46V43.043L75.7717 136H94.2187L124 43H102.849Z"
				fill="white"
			/>
		</svg>
	);
}

export default VersaPaySVG;
