import { BannerGroupRequest, BannerPlaceholderDetails, BannersForGroupsResponse } from 'features/types/banners';
import AxiosHelper from 'services/AxiosHelper';
import { lazyElements, lazyLoadImages } from 'shared/assets/lazy';
import { dispatchRocEvent } from 'shared/hooks/useRocEventHandler';
import 'styles/node-modules/tiny-slider/dist/tiny-slider.css';
import setupProductBanners from './commerce/banners.products';

/**
 * Generates a key for the provided banners group as a combinantion of groupCode, pageUrl, isRandom and index (for random banners ONLY)
 * @param bannerGroupRequest
 * @param index
 */
function generateKeyForGroupRequest(bannerGroupRequest: BannerGroupRequest, index: number): string {
	let key = '';

	if (bannerGroupRequest.groupCode) {
		key += bannerGroupRequest.groupCode + '_';
	}

	if (bannerGroupRequest.pageUrl) {
		key += bannerGroupRequest.pageUrl + '_';
	}

	key += bannerGroupRequest.isRandom ? 0 : 1;

	// append element's index in the group key only for random banners
	if (bannerGroupRequest.isRandom) {
		key += '_' + index.toString();
	}
	return key;
}

/**
 * Setup function for both client and server side banners.
 */
function setupBanners() {
	setupServerBanners();
	setupClientBanners();
}

/**
 * Setup function for the server-side rendered banners that is reponsible for setting up tracking/click events and initializing sliders
 */
function setupServerBanners() {
	// find all server-side rendered containers with banners
	const serverBannerContainers = document.querySelectorAll<HTMLElement>('[data-banner-type="server"]');

	if (!serverBannerContainers) {
		return;
	}

	// process server-side rendered containers to set them up
	for (let i = 0; i < serverBannerContainers.length; i++) {
		const serverBannersContainer = serverBannerContainers.item(i);

		// setup click event/impressions for all banners in the current container
		for (let j = 0; j < serverBannersContainer.childElementCount; j++) {
			const banner = serverBannersContainer.children.item(j) as HTMLElement;
			setupBannerClick(banner);

			const bannerData = banner.dataset;

			if (bannerData.name) {
				dispatchRocEvent('banner-impression', { bannerName: bannerData.name });
			}
		}

		// setup slider/carousel
		const isCarousel = serverBannersContainer.classList.contains('roc-slider');

		if (isCarousel) {
			setupSliderForCarousel(serverBannersContainer);
		}
	}
}

/**
 * Setup function for the client-side banners that is responsible for populating all the banners on a page
 */
async function setupClientBanners() {
	const bannerPlaceholders = document.querySelectorAll<HTMLElement>('[data-banner-type="client"]');

	if (!bannerPlaceholders) {
		return;
	}

	const bannerGroupsPlaceholderDetails: BannerPlaceholderDetails[] = [];

	// go over each banner placeholder and gather details for each element
	for (let i = 0; i < bannerPlaceholders.length; i++) {
		const bannerPlaceholder = bannerPlaceholders.item(i);
		const placeholderData = bannerPlaceholder.dataset;

		// details based on data-* attributes
		const requestData = {
			groupCode: placeholderData.groupCode,
			isRandom: placeholderData.isRandom === 'true',
			pageUrl: placeholderData.pageUrl,
		};

		// generate unique key for this placeholder/group
		// the key will be used to process response from the server
		const groupKey = generateKeyForGroupRequest(requestData, i);

		// add details about this placeholder/group into an array that will be used to create a request object
		bannerGroupsPlaceholderDetails.push({
			key: groupKey,
			placeholder: bannerPlaceholder,
			requestData,
		});
	}

	if (bannerGroupsPlaceholderDetails.length === 0) {
		// no client-side banners found, so let's exit.
		return;
	}

	try {
		// create request object + remove duplicated groups
		const distinctBannerGroups = bannerGroupsPlaceholderDetails
			.map((group) => {
				return { ...group.requestData, key: group.key };
			})
			.filter((elem, index, self) => {
				return self.findIndex((t) => t.key === elem.key) === index;
			});

		// get all the banners for the marked landing zones
		const response = await AxiosHelper.post<BannersForGroupsResponse>('/ajax/banners', {
			groupCodes: distinctBannerGroups,
		});

		const bannersFromServer = response.data.bannersForGroups;
		let bannerIndex = 0;

		// go over each landing zone and populate banners
		for (const placeholderDetails of bannerGroupsPlaceholderDetails) {
			// ensure that there is data for the requested group by checking if server response has the requested key
			if (!bannersFromServer.hasOwnProperty(placeholderDetails.key)) {
				console.error(`No matching banner group was found on the server (key: ${placeholderDetails.key})`);
				continue;
			}
			const bannersGroups = bannersFromServer[placeholderDetails.key];
			const isCarousel = placeholderDetails.placeholder.classList.contains('roc-slider');

			for (const banner of bannersGroups.banners) {
				const htmlBanner = document.createElement('div');
				htmlBanner.dataset.name = banner.name;
				bannerIndex++;

				switch (banner.type.name) {
					case 'WYSIWYG':
						if (banner.customHtml) {
							htmlBanner.innerHTML = banner.customHtml;
						}

						break;
					case 'Image':
						const img = document.createElement('img');

						// allow tiny slider to deal with lazy images if we have a carousel only
						if (isCarousel) {
							img.classList.add('tns-lazy-img');
						} else {
							img.classList.add('roc-lazy-image');
						}

						//#region HCL
						const imageDto = banner.imageDto;
						if (imageDto?.url) {
							img.src = '/images/placeholder/placeholder-image.png';
							img.setAttribute('data-src', imageDto?.url);
						}
						//#endregion

						if (banner.altTag) {
							img.alt = banner.altTag;
						}

						let elementToAppend: HTMLElement;
						if (banner.forwardUrl) {
							const link = document.createElement('a');
							link.href = banner.forwardUrl;

							if (banner.browserTarget) {
								link.target = banner.browserTarget;
							}

							link.appendChild(img);
							elementToAppend = link;
						} else {
							elementToAppend = img;
						}

						if (isCarousel) {
							// when rendering banners for carousel render them as display:none
							// because slider first needs to do the sizing and then it will make them visible
							htmlBanner.style.display = 'none';
						}

						htmlBanner.appendChild(elementToAppend);
						break;
					case 'Product':
						const reactRoot = document.createElement('div');
						reactRoot.id = `banner_product_${placeholderDetails.key}_${bannerIndex}`;
						htmlBanner.appendChild(reactRoot);

						// We need to place this at the end of the callback queue, in order to finalize lazy loading
						// before the React app can be initialized.
						setTimeout(() => {
							setupProductBanners(reactRoot.id, banner);
						});
						break;
					//#region HCL
					case 'Services':
						htmlBanner.classList.add('roc-services__item');

						const link = document.createElement('a');
						link.classList.add('roc-services__card');
						link.href = banner.linkUrl ?? '#';

						if (banner.browserTarget) {
							link.target = banner.browserTarget;
						}

						const image = document.createElement('span');
						image.classList.add('roc-services__background-image');
						image.style.backgroundImage = `url(${banner.imageUrl})`;

						const secondaryText = document.createElement('span');
						secondaryText.classList.add('roc-services__card-tag');
						secondaryText.innerHTML = banner.secondaryText ?? '';

						let titleElementToAppend: HTMLElement;

						if (banner.secondaryImageUrl) {
							const img = document.createElement('img');

							img.src = banner.secondaryImageUrl;
							img.setAttribute('data-src', banner.secondaryImageUrl);

							titleElementToAppend = img;
						} else {
							const title = document.createElement('h3');
							title.classList.add('roc-services__card-title');
							title.innerHTML = banner.title ?? '';

							titleElementToAppend = title;
						}

						const linkText = document.createElement('span');
						linkText.classList.add('roc-services__card-learnmore');
						linkText.innerHTML = banner.linkText ?? '';

						link.appendChild(image);
						link.appendChild(secondaryText);
						link.appendChild(titleElementToAppend);
						link.appendChild(linkText);

						htmlBanner.appendChild(link);

						break;
					case 'Catalog':
						//htmlBanner.classList.add('roc-services__item');

						const catalogHTML = `
							<div class="roc-product-catalog__wrap" style="background-image:url(${banner.imageUrl})">
								<h3>${banner.title}</h3>
								<a href="${banner.linkUrl}" ${banner.browserTarget ? 'target=' + banner.browserTarget : ''}>${banner.linkText}</a>
							</div>
						`;

						htmlBanner.innerHTML += catalogHTML;

						break;
					case 'Hero':
						const heroSliderItemDiv = document.createElement('div');
						heroSliderItemDiv.classList.add('roc-hero-banner__slider-item');

						const heroSliderDetailsDiv = document.createElement('div');
						heroSliderDetailsDiv.classList.add('roc-hero-banner__slider-details');

						const title = document.createElement('h2');
						title.classList.add('roc-hero-banner__slider-title');
						title.innerHTML = banner.title ?? '';

						const heroText = document.createElement('p');
						heroText.classList.add('roc-hero-banner__slider-text');
						heroText.innerHTML = banner.secondaryText ?? '';

						const heroLink = document.createElement('a');
						heroLink.classList.add('roc-btn');
						heroLink.href = banner.linkUrl ?? '#';

						const heroLinkText = document.createElement('span');
						heroLinkText.innerHTML = banner.linkText ?? '';

						if (banner.browserTarget) {
							heroLink.target = banner.browserTarget;
						}

						const heroProductDiv = document.createElement('div');
						heroProductDiv.classList.add('roc-hero-banner__product');

						const heroImage = document.createElement('img');
						heroImage.classList.add('roc-hero-banner__product-image');
						heroImage.src = `${banner.imageUrl}`;
						heroImage.alt = 'hero-image';

						heroSliderItemDiv.appendChild(heroSliderDetailsDiv);
						heroSliderDetailsDiv.appendChild(title);
						heroSliderDetailsDiv.appendChild(heroText);
						heroLink.appendChild(heroLinkText);
						heroSliderDetailsDiv.appendChild(heroLink);
						heroSliderItemDiv.appendChild(heroProductDiv);
						heroProductDiv.appendChild(heroImage);

						htmlBanner.appendChild(heroSliderItemDiv);

						break;
					//#endregion
				}

				setupBannerClick(htmlBanner);
				placeholderDetails.placeholder.appendChild(htmlBanner);
				dispatchRocEvent('banner-impression', { bannerName: banner.name }); // init banner impression
			}

			if (isCarousel) {
				setupSliderForCarousel(placeholderDetails.placeholder);
			} else {
				// client side banners are loaded with some delay, so the initial call to setup lazy loaded images
				// may have already run, so we need to call it ourselves.
				lazyLoadImages();
			}
		}
	} catch (error) {
		console.error('Unable to load banners:', error);
	}
}

/**
 * Setups click event for the banner
 *
 * @param banner
 */
function setupBannerClick(banner: HTMLElement) {
	banner.onclick = (event: MouseEvent) => {
		const clickedBannerData = (event.currentTarget as HTMLElement).dataset;
		if (clickedBannerData.name) {
			dispatchRocEvent('banner-click', { bannerName: clickedBannerData.name });
		}
	};
}

/**
 * Setups slider for the provided banner container
 *
 * @param bannerPlaceHolder
 */
function setupSliderForCarousel(bannerPlaceHolder: HTMLElement) {
	if (bannerPlaceHolder.hasAttribute('has-tiny-slider')) {
		return;
	}

	bannerPlaceHolder.setAttribute('has-tiny-slider', 'true');

	lazyElements({
		elements: [bannerPlaceHolder],
		callback: (isIntersecting) => {
			// avoid rebinding if tiny slider has already been setup against the element.
			if (!isIntersecting || bannerPlaceHolder.classList.contains('tns-slider')) {
				return;
			}

			const showControls =
				(bannerPlaceHolder.dataset.showControls &&
					bannerPlaceHolder.dataset.showControls.toLowerCase() === 'true') ||
				false;

			const tnsPromise = import('tiny-slider/src/tiny-slider').then((module) => module.tns);
			(async () => {
				const tns = await tnsPromise;

				const slider = tns({
					container: bannerPlaceHolder,
					items: (bannerPlaceHolder.dataset.items && parseInt(bannerPlaceHolder.dataset.items)) || 3,
					slideBy: (bannerPlaceHolder.dataset.slideBy && parseInt(bannerPlaceHolder.dataset.slideBy)) || 1,
					autoplay: bannerPlaceHolder.dataset.autoplay?.toLowerCase() === 'true',
					controls: showControls,
					nav: showControls,
					autoplayButtonOutput: showControls,
					lazyload: true,
					controlsText: [
						"<svg><use xlink:href='#icon-left-chevron'></use></svg>",
						"<svg><use xlink:href='#icon-right-chevron'></use></svg>",
					],
					navPosition: 'bottom',
					onInit: () => {
						// on slider initialization we have to make sure that all banners are visible on the front-end
						// that's why we iterate through all of them and reset their display style which would be set to display:none on the server-side
						if (bannerPlaceHolder.childElementCount === 0) {
							return;
						}

						for (let i = 0; i < bannerPlaceHolder.childElementCount; i++) {
							const banner = bannerPlaceHolder.children.item(i) as HTMLElement;

							if (banner) {
								banner.style.display = '';
							}
						}
					},
				});

				slider.events.on('indexChanged', (info) => {
					const bannerData = (info.slideItems[info.index] as HTMLElement).dataset;
					if (bannerData.name) {
						dispatchRocEvent('banner-impression', { bannerName: bannerData.name });
					}
				});
			})();
		},
	});
}

export default setupBanners;
