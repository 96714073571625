import React from 'react';
import { SVGProps } from './SVGProps';
import classnames from 'classnames';

/**
 * Menu SVG
 *
 * @returns
 */
function MenuSVG({ className, ...rest }: SVGProps) {
	return (
		<svg viewBox="0 0 24 24" className={classnames('roc-icon icon-apple-pay', className)} {...rest}>
			<path d="M3 13h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1zM3 7h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1zM3 19h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1z"></path>
		</svg>
	);
}

export default MenuSVG;
