// HEADS UP: NAVIGATION MUST BE INCLUDED FIRST OR IE11 WILL BREAK BECAUSE LIFE ISN'T FAIR.
// IT HAS SOMETHING TO DO WITH THE FOR CONST OF LOOP INSIDE ACCESSIBLE NAVIGATION
import setupNavigation from 'features/setup/navigation';
import setupAnnouncements from 'features/announcements';
import setupHeader from 'features/header';
import setupBanners from 'features/setup/banners';
import setupReCaptcha from 'features/setup/recaptcha';
import setupUserType from 'features/setup/userType';
import setupCarousel from 'features/setup/carousel';

import 'shared/assets/lazy';
// #region HCL
import setupFeaturedPostNotification from 'features/setup/featuredPostNotification';
import setupScrollBtn from 'features/setup/scrollBtn';
// #endregion

// #region Commerce
import setupPDPTabs from 'features/setup/commerce/setupPDPTabs';
import setupRelatedProducts from 'features/setup/commerce/relatedProducts';
import { setupOrganizationRemovalNotification } from 'features/commerce/organization/index';

function setupCommerce() {
	setupPDPTabs();
	setupRelatedProducts();
	setupOrganizationRemovalNotification();
}
// #endregion

/**
 * Set up various modules needed for the web front end.
 */
export default function setup() {
	setupBanners();
	setupHeader();
	setupReCaptcha();
	setupUserType();
	setupNavigation();
	setupAnnouncements();

	// #region Commerce
	setupCommerce();
	// #endregion

	// #region HCL
	setupFeaturedPostNotification();
	setupScrollBtn();
	setupCarousel();
	// #endregion
}
