import React from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Localizer } from 'services/Localizer';
import Breadcrumbs, { BreadcrumbItem } from 'shared/components/Breadcrumbs';
import { toKebabCase } from 'util/StringUtil';
import {
	ORGANIZATION_ORDERS_URL,
	ORGANIZATION_SHIPTOS_URL,
	ORGANIZATION_USERS_CREATE_URL,
	ORGANIZATION_USERS_URL,
} from './routes';

/**
 * Breadcrumbs component for the organization SPA
 */
export default function OrganizationBreadcrumbs() {
	const location = useLocation();

	let label = '';
	let url = '';
	const orgLabel = Localizer('OrganizationHeaderText');
	let breadcrumbPath: BreadcrumbItem[] | null = null;
	if (location.pathname.startsWith(ORGANIZATION_ORDERS_URL)) {
		label = Localizer('Orders');
		url = ORGANIZATION_ORDERS_URL;
	} else if (location.pathname.startsWith(ORGANIZATION_USERS_CREATE_URL)) {
		label = Localizer('AddNewUser');
		url = ORGANIZATION_USERS_CREATE_URL;
		breadcrumbPath = [
			{
				label: Localizer('OrgUsers'),
				url: ORGANIZATION_USERS_URL,
			},
			{
				label: label,
				url: url,
			},
		];
	} else if (location.pathname.startsWith(ORGANIZATION_USERS_URL)) {
		label = Localizer('OrgUsers');
		url = ORGANIZATION_USERS_URL;
	} else if (location.pathname.startsWith(ORGANIZATION_SHIPTOS_URL)) {
		label = Localizer('ShipTosText');
		url = ORGANIZATION_SHIPTOS_URL;
	} else {
		// ROC-3667 temporary default page
		label = Localizer('Orders');
		url = ORGANIZATION_ORDERS_URL;
		// default page
		// label = Localizer('OrganizationOverviewText');
		// url = ORGANIZATION_OVERVIEW_URL;
	}

	// ROC-3667 temporary not used
	const organizationItem: BreadcrumbItem = {
		label: orgLabel,
	};

	const item: BreadcrumbItem = {
		label,
		url,
	};

	breadcrumbPath = breadcrumbPath ? breadcrumbPath : [item];

	return (
		<Breadcrumbs
			breadcrumbPath={[organizationItem, ...breadcrumbPath]}
			renderPathLink={(item) => {
				return item.url ? (
					<NavLink
						className="roc-breadcrumbs-bar__link"
						to={item.url}
						data-testid={'breadcrumb-link-' + toKebabCase(item.label)}
					>
						{item.label}
					</NavLink>
				) : (
					<span
						className="roc-breadcrumbs-bar__text"
						data-testid={'breadcrumb-link-' + toKebabCase(item.label)}
					>
						{item.label}
					</span>
				);
			}}
		/>
	);
}
