export default function setupScrollBtn() {
	const rootElement = document.documentElement;
	const scrollButton = document.getElementById('scrollTop');

	function handleScroll() {
		const scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
		const showButton = rootElement.scrollTop / scrollTotal > 0.2;

		if (showButton) {
			scrollButton?.classList.remove('hidden');
		} else {
			scrollButton?.classList.add('hidden');
		}
	}

	document.addEventListener('scroll', handleScroll);

	scrollButton?.addEventListener('click', () => {
		rootElement.scrollTop = 0;
	});
}
