import React from 'react';
import { Route } from 'react-router';
import { ServerData } from 'services/ServerData';
import Spinner from 'shared/components/Spinner';
import { OrganizationUIComponents } from './OrganizationUIComponents';

export const ORGANIZATION_PREFIX = '/organization';
export const ORGANIZATION_OVERVIEW_URL = `${ORGANIZATION_PREFIX}`;
export const ORGANIZATION_USERS_URL = `${ORGANIZATION_PREFIX}/users`;
export const ORGANIZATION_USERS_CREATE_URL = `${ORGANIZATION_USERS_URL}/create`;
export const UPDATE_ORGANIZATION_USER_URL = `${ORGANIZATION_PREFIX}/user`;
export const UPDATE_USER_ORGANIZATION_STATUS_URL = `${UPDATE_ORGANIZATION_USER_URL}/organization-status`;
export const UPDATE_USER_DEFAULT_ORGANIZATION_URL = `/user/organization`;
export const INVALIDATE_USER_ORGANIZATION_NOTIFICATION_CACHE_URL = `${UPDATE_USER_DEFAULT_ORGANIZATION_URL}/cache`;
export const ORGANIZATION_ORDERS_URL = `${ORGANIZATION_PREFIX}/orders`;
export const ORGANIZATION_SHIPTOS_URL = `${ORGANIZATION_PREFIX}/ship-to`;
export const ORGANIZATION_ORDER_DETAILS = `${ORGANIZATION_ORDERS_URL}/:orderNo`;
export const ORGANIZATION_MANAGE_SHIPTOS_URL = ''; // TBD

const OrganizationOrderDetailsComponent = React.lazy(() => {
	const orderDetailsComponent =
		ServerData?.OrderDetailsUIComponent && ServerData?.OrderDetailsUIComponent in OrganizationUIComponents
			? ServerData.OrderDetailsUIComponent
			: OrganizationUIComponents.DefaultOrganizationOrderDetails;
	return import(/* webpackChunkName: "organization-order-details" */ `./orders/${orderDetailsComponent}`);
});

const ShiptosComponent = React.lazy(() => import(/* webpackChunkName: "ship-to" */ './ship-to/OrganizationShipTos'));

const UsersComponent = React.lazy(() => import(/* webpackChunkName: "org-users" */ './users/OrganizationUsers'));

const OrganizationOrdersComponent = React.lazy(
	() => import(/* webpackChunkName: "org-orders" */ './orders/OrganizationOrders'),
);

const OrganizationUsersCreateComponent = React.lazy(
	() => import(/* webpackChunkName: "org-users-create" */ './users/OrganizationUsersCreate'),
);

export default (
	<React.Suspense fallback={<Spinner />}>
		{/*  ROC-3667 temporary hidden
		<Route exact path={ORGANIZATION_PREFIX} component={OrganizationsOverviewComponent} /> */}
		<Route exact path={ORGANIZATION_SHIPTOS_URL} component={ShiptosComponent} />
		<Route exact path={ORGANIZATION_USERS_URL} component={UsersComponent} />
		<Route exact path={ORGANIZATION_ORDERS_URL} component={OrganizationOrdersComponent} />
		<Route exact path={ORGANIZATION_ORDER_DETAILS} component={OrganizationOrderDetailsComponent} />
		<Route exact path={ORGANIZATION_USERS_CREATE_URL} component={OrganizationUsersCreateComponent} />
	</React.Suspense>
);
