import React from 'react';
import { SVGProps } from './SVGProps';
import classnames from 'classnames';

/**
 * Left chevron SVG
 *
 * @returns
 */
function LeftChevronSVG({ className, ...rest }: SVGProps) {
	return (
		<svg
			viewBox="0 0 24 24"
			className={classnames('roc-icon', className)}
			focusable="false"
			aria-hidden="true"
			{...rest}
		>
			<path d="M15.707 17.293l-5.293-5.293 5.293-5.293c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-6 6c-0.391 0.391-0.391 1.024 0 1.414l6 6c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414z"></path>
		</svg>
	);
}

export default LeftChevronSVG;
