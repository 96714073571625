/**
 * THIS HAS A COPY ON ROC.WEB. DO NOT MAKE CHANGES HERE WITHOUT ALSO MAKING THEM IN THE OTHER FILE.
 *
 * THIS MUST ALSO BE KEPT IN SYNC WITH THE "RazorDisplayPrice" VIEW COMPONENT.
 *
 * DIVERGENCES:
 * - The style "visually-hidden" DOES NOT EXIST ON ROC.WEB. Change this to "roc-visually-hidden"
 */

import classnames from 'classnames';
import React from 'react';
import { Localizer } from 'services/Localizer';
import { CssClasses, ProductPricingProps } from 'shared-client/types/commerce/product-details';
import {
	AmbiguousDisplayPrice,
	MissingDisplayPrice,
	PartialDisplayPrice,
	Price,
	PriceDisplayRule,
	PriceSpan,
	SimpleDisplayPrice,
} from 'shared-client/types/commerce/display-price';

import { PricingResourceKeys } from 'features/commerce/pricing/resources';

const GetCssClasses = (className: string | undefined, inline: boolean) => {
	const priceClass = ['roc-pricing__price', className ? className + '__price' : null];

	return {
		base: ['roc-pricing', { 'roc-pricing--inline': inline }, className ? className : null],
		normalPrice: ['roc-pricing__price', className ? className + '__price' : null],
		crossedOutPrice: [
			priceClass,
			'roc-pricing__price--original',
			className ? className + '__price--original' : null,
		],
		highlightedPrice: [priceClass, 'roc-pricing__price--sale', className ? className + '__price--sale' : null],
	};
};

/**
 * Renders a product price. This could be a simple price "$4.99", a price that's on sale "~~$4.99~~ $3.99",
 * a range of prices, an ambiguous price or potentially others.
 *
 * The pricing information is expected to have come from the server where it has already been validated.
 * It is the responsibility of the caller to verify pricing objects passed to ProductPricing are valid.
 */
export default function ProductPricing(props: ProductPricingProps) {
	// #region HCL
	const { displayPrice, className, inline = false, testId = 'product-price', roundValue } = props;
	// #endregion

	// The server/caller is trusted to have provided valid props.
	// Do no validation here.

	if (displayPrice == null) {
		// Missing price. Remove this if MissingPrice functionality is ever needed beyond "don't render"
		return null;
	}

	const css = GetCssClasses(className, inline);

	let renderResult: JSX.Element | null;
	switch (displayPrice.displayRule) {
		case PriceDisplayRule.Missing:
			renderResult = renderMissingPrice(css, displayPrice);
			break;

		case PriceDisplayRule.Normal:
			renderResult = renderNormalPrice(css, displayPrice, testId);
			break;

		case PriceDisplayRule.AmbiguousRange:
			// #region HCL
			renderResult = renderAmbiguousPrice(css, displayPrice, testId, roundValue);
			// #endregion
			break;

		default:
			throw new Error('Erroneous price display rule');
	}

	return (
		<span className={classnames(css.base)} data-testid={`wrapper-${testId}`}>
			{renderResult}
		</span>
	);
}

/**
 * Renders a missing price, eg nothing. This currently renders nothing but could be updated to render "Not For Sale" for example.
 * @param css The set of css rules that can be applied.
 * @param price The price object
 */
// eslint-disable-next-line no-unused-vars
function renderMissingPrice(css: CssClasses, price: MissingDisplayPrice) {
	return null;
}

/**
 * Renders a normal price. This is a price that might be on sale and may either be a unit price (eg $4.99) or a
 * range (eg $3.99 - $7.99).
 * @param css The set of css rules that can be applied.
 * @param price The price object
 */
function renderNormalPrice(css: CssClasses, price: SimpleDisplayPrice, testId: string) {
	// conditionally cross out the base price
	const basePriceClass = price.salePrice != null ? css.crossedOutPrice : css.normalPrice;

	return (
		<>
			<span className={classnames(basePriceClass)}>
				<span className="roc-visually-hidden">{Localizer('OriginalPrice')}</span>
				<span data-testid={testId}>
					<VariantPriceComponent price={price.basePrice} />
				</span>
			</span>
			{price.salePrice != null ? (
				<span className={classnames(css.highlightedPrice)}>
					<span className="roc-visually-hidden">{Localizer('SalePrice')}</span>
					<span data-testid={`sale-${testId}`}>
						<VariantPriceComponent price={price.salePrice} />
					</span>
				</span>
			) : null}
		</>
	);
}

/**
 * Renders an ambiguous price. This is a compound price representing multiple different products where the upperbound of the price
 * can't be reported in a useful way. The units of measure between the base and sale price may be different.
 * @param css The set of css rules that can be applied.
 * @param price The price object
 */
// #region HCL
function renderAmbiguousPrice(css: CssClasses, price: AmbiguousDisplayPrice, testId: string, roundValue?: boolean) {
	// #endregion
	// conditionally cross out the base price
	const basePriceClass = price.salePrice != null ? css.crossedOutPrice : css.normalPrice;
	// #region HCL
	const roundedPrice = roundValue ? price.basePrice.rawValue.toFixed(2) : price.basePrice.value;
	// #endregion
	return (
		<>
			<span className={classnames(basePriceClass)}>
				<span className="roc-visually-hidden">{Localizer('OriginalPrice')}</span>
				<span data-testid={`ambiguous-original-${testId}`}>
					{/* #region HCL */}
					<FormatAmbiguousPriceSpan price={roundedPrice} unitName={price.basePrice.unit!.label} />
					{/* #region HCL */}
				</span>
			</span>
			{price.salePrice != null ? (
				<span className={classnames(css.highlightedPrice)}>
					<span className="roc-visually-hidden">{Localizer('SalePrice')}</span>
					<span data-testid={`ambiguous-sale-${testId}`}>
						<FormatAmbiguousPriceSpan
							price={price.salePrice.value}
							unitName={price.salePrice.unit!.label}
						/>
					</span>
				</span>
			) : null}
		</>
	);
}

/**
 * Renders a partial display price (eg it may be either a simple price or a range price)
 */
function VariantPriceComponent({ price }: { price: PartialDisplayPrice }) {
	const label = price.unit?.label;

	switch (typeof price.value) {
		case 'string':
			return <FormatPrice price={price.value} unitName={label} />;

		case 'object':
			return <FormatPriceSpan priceRange={price.value} unitName={label} />;
	}
}

function FormatAmbiguousPriceSpan(props: { price: Price; unitName: string }) {
	// Ambiguous prices always have a unit of measure. It is never optional.
	return <>{Localizer<PricingResourceKeys>('AmbiguousPriceRangeWithUnit', props.price, props.unitName)}</>;
}

/**
 * Renders a price range (eg $14.99 - $19.99) with an optional unit of measure
 */
function FormatPriceSpan(props: { priceRange: PriceSpan; unitName: string | undefined }) {
	return props.unitName != null ? (
		<>
			{Localizer<PricingResourceKeys>(
				'PriceRangeWithUnit',
				props.priceRange.from,
				props.priceRange.to,
				props.unitName,
			)}
		</>
	) : (
		<>{Localizer<PricingResourceKeys>('PriceRangeWithoutUnit', props.priceRange.from, props.priceRange.to)}</>
	);
}

/** Formats the price with the unit of measure (if present) appended. */
function FormatPrice(props: { price: Price; unitName?: string }) {
	return props.unitName != null ? (
		<>{Localizer<PricingResourceKeys>('PriceWithUnit', props.price, props.unitName)}</>
	) : (
		<>{Localizer<PricingResourceKeys>('PriceWithoutUnit', props.price)}</>
	);
}
