import React from 'react';
import { RouteProps } from 'react-router';

//#region URLs
export const MY_ACCOUNT_PATH_PREFIX = '/my-account';
export const MY_ACCOUNT_PERSONAL_DATA_URL = `${MY_ACCOUNT_PATH_PREFIX}/personal-data`;
export const MY_ACCOUNT_AUTHENTICATOR_URL = `${MY_ACCOUNT_PATH_PREFIX}/settings/authenticator`;
export const MY_ACCOUNT_GENERATE_RECOVERY_CODES_URL = `${MY_ACCOUNT_AUTHENTICATOR_URL}/generate-recovery-codes`;
export const MY_ACCOUNT_CONFIGURE_AUTHENTICATOR_URL = `${MY_ACCOUNT_AUTHENTICATOR_URL}/configure`;
export const MY_ACCOUNT_LOGIN_URL = `${MY_ACCOUNT_PATH_PREFIX}/login`;
export const MY_ACCOUNT_LOGOUT_URL = `${MY_ACCOUNT_PATH_PREFIX}/logout`;
export const MY_ACCOUNT_WALLET_URL = `${MY_ACCOUNT_PATH_PREFIX}/wallet`;
export const MY_ACCOUNT_WALLET_CREATE_URL = `${MY_ACCOUNT_WALLET_URL}/create`;
export const MY_ACCOUNT_ADDRESS_BOOK_URL = `${MY_ACCOUNT_PATH_PREFIX}/address-book`;
export const MY_ACCOUNT_ADDRESS_BOOK_CREATE_URL = `${MY_ACCOUNT_ADDRESS_BOOK_URL}/create`;
export const MY_ACCOUNT_ADDRESS_BOOK_EDIT_URL = `${MY_ACCOUNT_ADDRESS_BOOK_URL}/edit`;
//#region Commerce
export const MY_ACCOUNT_SETTINGS_URL = `${MY_ACCOUNT_PATH_PREFIX}/settings`;
//#endregion Commerce
//#endregion URLs

//#region Components
// #region HCL
// const SettingsComponent = React.lazy(() => import(/* webpackChunkName: "my-account-settings" */ './settings/Settings'));
// #region

const GenerateRecoveryCodesComponent = React.lazy(
	() =>
		import(
			/* webpackChunkName: "my-account-generate-recovery-codes" */ './generate-recovery-codes/GenerateRecoveryCodesContainer'
		),
);
const ConfigureAuthenticatorComponent = React.lazy(
	() =>
		import(
			/* webpackChunkName: "my-account-configure-authenticator" */ './configure-authenticator/ConfigureAuthenticator'
		),
);

const WalletComponent = React.lazy(() => import(/* webpackChunkName: "my-account-wallet" */ './wallet/Wallet'));
const WalletEditComponent = React.lazy(() => import(/* webpackChunkName: "my-account-wallet" */ './wallet/WalletEdit'));

//#endregion Components

//#region Routes
const generateRecoverCodesRoute = {
	exact: true,
	path: MY_ACCOUNT_GENERATE_RECOVERY_CODES_URL,
	component: GenerateRecoveryCodesComponent,
};

const configureAuthenticatorRoute = {
	exact: true,
	path: MY_ACCOUNT_CONFIGURE_AUTHENTICATOR_URL,
	component: ConfigureAuthenticatorComponent,
};

const walletRoute = {
	exact: true,
	path: MY_ACCOUNT_WALLET_URL,
	component: WalletComponent,
};

const walletCreateRoute = {
	exact: true,
	path: MY_ACCOUNT_WALLET_CREATE_URL,
	component: WalletEditComponent,
};

//#region Commerce
// Note: in Framework we want MY_ACCOUNT_PATH_PREFIX to point to SettingsComponent,
// but in Commerce we want it to point to AccountOverviewComponent
//#region HCL
// const settingsRoute = {
// 	exact: true,
// 	path: MY_ACCOUNT_SETTINGS_URL,
// 	component: SettingsComponent,
// };
//#endregion Commerce
//#endregion Routes

export const rocRoutes: RouteProps[] = [
	generateRecoverCodesRoute,
	configureAuthenticatorRoute,
	walletRoute,
	walletCreateRoute,
];
//#endregion HCL
