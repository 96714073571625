import type { Dialog, DialogContent } from '@reach/dialog';
import classnames from 'classnames';
import React from 'react';
import { Localizer } from 'services/Localizer';
import 'styles/node-modules/@reach/dialog/styles.css';
import Spinner from './Spinner';
import XSVG from './svg/XSVG';

const LazyDialog = React.lazy<typeof Dialog>(() =>
	import('@reach/dialog').then((module) => ({ default: module.Dialog })),
);
const LazyDialogContent = React.lazy<typeof DialogContent>(() =>
	import('@reach/dialog').then((module) => ({ default: module.DialogContent })),
);

// TODO: the types are currently out of date so they don't include the definition for dangerouslyBypassFocusLock. Add the types here once they are updated
// https://github.com/DefinitelyTyped/DefinitelyTyped/tree/master/types/reach__dialog
const LazyDialogOverlay = React.lazy<any>(() =>
	import('@reach/dialog').then((module) => ({ default: module.DialogOverlay } as any)),
);

export type ModalSize = 'xs' | 'sm' | 'md' | 'lg' | 'xlg' | 'full_width';

interface ModalProps {
	isOpen: boolean;
	hideCloseButton?: boolean;
	children: React.ReactNode;
	titleIcon?: React.ReactNode;
	size?: ModalSize;
	headerDivider?: boolean;
	bypassFocusLock?: boolean;
	subTitle?: string;
	className?: string;
	renderActions?: () => React.ReactNode;
	closeModal: () => void;
	renderTitle: () => React.ReactNode;
	renderSubtitle?: () => React.ReactNode;
}

/**
 * Reusuable modal component with raised open state, takes title and children as props.
 *
 * @export
 * @param {ModalProps} props
 * @returns
 */
export default function Modal(props: ModalProps) {
	const {
		isOpen,
		hideCloseButton,
		size = 'md',
		closeModal,
		renderTitle,
		renderSubtitle,
		titleIcon,
		renderActions,
		children,
		headerDivider = false,
		bypassFocusLock = false,
		subTitle,
		className,
	} = props;

	const innerModal = (
		<>
			<div
				className={classnames('roc-modal__header', {
					'roc-modal__header--divider': headerDivider,
					[className + '__header']: className,
				})}
			>
				<div
					className={classnames('roc-modal__title-close-wrapper', {
						[className + '__title-close-wrapper']: className,
					})}
				>
					<div
						className={classnames('roc-modal__title-container', {
							[className + '__title-container']: className,
						})}
					>
						{titleIcon && (
							<span
								className={classnames('roc-modal__icon', {
									[className + '__icon']: className,
								})}
							>
								{titleIcon}
							</span>
						)}

						<h2
							className={classnames('roc-modal__title', {
								[className + '__title']: className,
							})}
						>
							{renderTitle()}
						</h2>

						{renderSubtitle && (
							<h3
								className={classnames('roc-modal__sub-title', {
									[className + '__sub-title']: className,
								})}
							>
								{renderSubtitle()}
							</h3>
						)}
					</div>
					{hideCloseButton ? null : (
						<div
							className={classnames('roc-modal__close', {
								[className + '__close']: className,
							})}
						>
							<button
								className={classnames('roc-modal__close-btn', {
									[className + '__close-btn']: className,
								})}
								onClick={() => closeModal()}
								type="button"
								data-testid="close-modal-btn"
							>
								<span
									className={classnames('roc-modal__close-text', {
										[className + '__close-text']: className,
									})}
								>
									{Localizer('Close')}
								</span>
								<span
									className={classnames('roc-modal__close-icon', {
										[className + '__close-icon']: className,
									})}
								>
									<XSVG />
								</span>
							</button>
						</div>
					)}
				</div>

				{subTitle ? (
					<p
						className={classnames('roc_modal__subtitle', {
							[className + '__subtitle']: className,
						})}
					>
						{subTitle}
					</p>
				) : null}
			</div>

			<div
				className={classnames('roc-modal__body', {
					[className + '__body']: className,
				})}
			>
				{children}
			</div>
			{renderActions ? (
				<div
					className={classnames('roc-modal__footer', {
						[className + '__footer']: className,
					})}
				>
					{renderActions()}
				</div>
			) : null}
		</>
	);

	const ariaLabel = Localizer('ModalLabel');

	return bypassFocusLock ? (
		<React.Suspense fallback={<Spinner />}>
			<LazyDialogOverlay
				isOpen={isOpen}
				onDismiss={() => closeModal()}
				dangerouslyBypassFocusLock={bypassFocusLock}
			>
				<LazyDialogContent
					className={classnames('roc-modal', {
						['roc-modal--' + size]: size,
					})}
					aria-label={ariaLabel}
					as={'div'}
				>
					{innerModal}
				</LazyDialogContent>
			</LazyDialogOverlay>
		</React.Suspense>
	) : (
		<React.Suspense fallback={<Spinner />}>
			<LazyDialog
				isOpen={isOpen}
				className={classnames('roc-modal', {
					['roc-modal--' + size]: size,
				})}
				onDismiss={() => closeModal()}
				aria-label={ariaLabel}
				as={'div'}
			>
				{innerModal}
			</LazyDialog>
		</React.Suspense>
	);
}
