import React from 'react';
import { Router, Switch } from 'react-router';
import Layout from './Layout';
import { createBrowserHistory } from 'history';
import { addUnauthorizedAccessInterceptor } from 'services/AxiosHelper';
import routes from './routes';

const browserHistory = (window as any).browserHistory || createBrowserHistory();

addUnauthorizedAccessInterceptor(browserHistory);

/**
 * Organization component with router (SPA)
 */
export default function Organization() {
	return (
		<div className="roc-container">
			<Router history={browserHistory}>
				<Switch>
					{/* The Layout needs to be re-rendered on routing because of the dynamic header title and svg */}
					<Layout>{routes}</Layout>
				</Switch>
			</Router>
		</div>
	);
}
