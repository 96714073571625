import React, { useEffect, useState } from 'react';
import { Localizer } from 'services/Localizer';
import { getUserData, UserData } from 'services/UserData';
// #region HCL
// import CheckClipboardSVG from 'shared/components/svg/CheckClipBoard';
// #endregion
import UserSVG from 'shared/components/svg/UserSVG';
import GearSVG from 'shared/components/svg/GearSVG';
import LockSimpleSVG from 'shared/components/svg/LockSimpleSVG';
import HeaderUtilNav, {
	// #region HCL
	HeaderUtilExternalLoginLink,
	HeaderUtilExternalRegisterLink,
	// #endregion HCL
	HeaderUtilNavItemLink,
	HeaderUtilNavItemPopover,
} from './HeaderUtilNav';
import {
	MY_ACCOUNT_PATH_PREFIX,
	MY_ACCOUNT_WALLET_URL,
	// #region HCL
	// MY_ACCOUNT_ADDRESS_BOOK_URL,
	// #endregion
} from 'features/my-account/routes.roc';
import AccountUrls from 'shared/util/AccountUrls';
import RenderImpersonationHeader from 'shared/components/RenderImpersonationHeader';
import CreditCardSimpleSVG from 'shared/components/svg/CreditCardSimpleSVG';

// #region Commerce
import { MY_ACCOUNT_SETTINGS_URL } from 'features/my-account/routes.roc';
import { HeaderUtilNavItemCart } from './HeaderUtilNav';
import useRocEventHandler from 'shared/hooks/useRocEventHandler';
// #region HCL
// import MapPinSVG from 'shared/components/svg/MapPinSVG';
// #endregion
import HouseSVG from 'shared/components/svg/HouseSVG';
import {
	MY_ACCOUNT_STORE_URL,
	MY_ACCOUNT_ORDERS_URL,
	MY_ACCOUNT_SAVED_FOR_LATER_URL,
	MY_ACCOUNT_SAVED_CARTS_URL,
	MY_ACCOUNT_BUY_AGAIN_URL,
	MY_ACCOUNT_SHOPPING_LISTS_URL,
	//#region HCL
	MY_ACCOUNT_REVIEWS_URL,
	MY_ACCOUNT_SHOPPER_APPLICATIONS_URL,
	//#endregion
} from 'features/my-account/routes.commerce';
import ClockSVG from 'shared/components/svg/ClockSVG';
import BookmarkSVG from 'shared/components/svg/BookmarkSVG';
import QuickOrderSVG from 'shared/components/svg/QuickOrderSVG';
import CartDownArrowSVG from 'shared/components/svg/CartDownArrowSVG';
import OrganizationHeaderMenu from 'features/commerce/organization/OrganizationHeaderMenu';
import ShoppingListSVG from 'shared/components/svg/ShoppingListSVG';
import StarEmptySVG from 'shared/components/svg/StarEmptySVG';
import VersaPaySVG from 'shared/components/svg/VersaPaySVG';

// #endregion Commerce

export const headerUtilsIconClass = 'roc-utlity-nav__menu-item-icon';

/**
 * Component that manages the header utilities, such as login, location, etc.
 *
 * @export
 * @returns
 */
export default function HeaderUtils() {
	const [userData, setUserData] = useState<UserData | null>(null);
	const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

	// #region Commerce
	const [hideWalletNavigation, setHideWalletNavigation] = useState<boolean>(false);

	useRocEventHandler(
		'roc-cart-summary-updated',
		({ cartSummary }) => {
			setUserData({
				...userData,
				cartSummary,
			});
		},
		[userData],
	);

	// #endregion

	// #region HCL
	const [versaPayEnabled, setVersaPayEnabled] = useState<boolean>(false);
	const [versaPayRedirectUrl, setVersaPayRedirectUrl] = useState('');
	// #endregion

	useEffect(() => {
		(async () => {
			try {
				const data = (await getUserData()).data;

				setUserData(data);

				if (data.impersonation != null) {
					RenderImpersonationHeader();
				}

				// #region Commerce
				if (data?.summary?.organization) {
					setHideWalletNavigation(
						!data.summary.organization.creditCardAllowed && !data.summary.hasSavedPaymentMethods,
					);
				}
				// #endregion

				// #region HCL
				if (data?.summary?.versaPayRedirectUrl) {
					setVersaPayEnabled(data.summary.versaPayEnabled);
					setVersaPayRedirectUrl(data.summary.versaPayRedirectUrl);
				}
				// #endregion
			} catch (error) {
				console.error('Unable to load user data.', error);
			}
		})();
	}, []);

	if (!userData) {
		return null;
	}

	const iconClass = 'roc-utlity-nav__menu-item-link-icon';

	return (
		<>
			<HeaderUtilNav>
				{userData?.summary?.isSignedIn ? (
					<>
						<HeaderUtilNavItemPopover
							isOpen={popoverOpen}
							openPopover={() => setPopoverOpen(true)}
							closePopover={() => setPopoverOpen(false)}
							icon={<UserSVG className={headerUtilsIconClass} />}
							text={Localizer('HeaderWelcomeText', userData.summary.firstName || 'User')}
							title={Localizer('MyAccount')}
							size="xs"
							testId="my-account"
						>
							<ul className="roc-utlity-nav__menu">
								{
									// #region Commerce
								}
								<li className="roc-utlity-nav__menu-item">
									<a
										href={MY_ACCOUNT_PATH_PREFIX}
										className="roc-utlity-nav__menu-item-link"
										data-testid="my-account-popover-account-overview-link"
									>
										<UserSVG className={iconClass} />
										{Localizer('HeaderUtilNavAccountOverview')}
									</a>
								</li>

								<li className="roc-utlity-nav__menu-item">
									<a
										href={MY_ACCOUNT_ORDERS_URL}
										className="roc-utlity-nav__menu-item-link"
										data-testid="my-account-popover-orders-link"
									>
										<ClockSVG className={iconClass} />
										{Localizer('Orders')}
									</a>
								</li>

								<li className="roc-utlity-nav__menu-item">
									<a
										href={MY_ACCOUNT_BUY_AGAIN_URL}
										className="roc-utlity-nav__menu-item-link"
										data-testid="my-account-popover-buy-again-link"
									>
										<CartDownArrowSVG className={iconClass} />
										{Localizer('BuyAgain')}
									</a>
								</li>

								{/*#region HCL*/}
								{/* <li className="roc-utlity-nav__menu-item">
									<a
										href={MY_ACCOUNT_ADDRESS_BOOK_URL}
										className="roc-utlity-nav__menu-item-link"
										data-testid="my-account-popover-addresses-link"
									>
										<MapPinSVG className={iconClass} />
										{Localizer('AddressBookPageTitleText')}
									</a>
								</li> */}

								<li className="roc-utlity-nav__menu-item">
									<a
										href={MY_ACCOUNT_REVIEWS_URL}
										className="roc-utlity-nav__menu-item-link"
										data-testid="my-account-popover-review-link"
									>
										<StarEmptySVG className={iconClass} />
										{Localizer('Reviews')}
									</a>
								</li>

								<li className="roc-utlity-nav__menu-item">
									<a
										href={MY_ACCOUNT_SHOPPER_APPLICATIONS_URL}
										className="roc-utlity-nav__menu-item-link"
										data-testid="my-account-popover-addresses-link"
									>
										<ShoppingListSVG className={iconClass} />
										{Localizer('ShopperApplications')}
									</a>
								</li>

								{versaPayEnabled && (
									<li className="roc-utlity-nav__menu-item">
										<a
											href={versaPayRedirectUrl}
											target="_blank"
											rel="noopener noreferrer"
											className="roc-utlity-nav__menu-item-link"
										>
											<VersaPaySVG className={iconClass} />
											{Localizer('Invoices')}
										</a>
									</li>
								)}
								{/*#endregion*/}

								{!hideWalletNavigation ? (
									<>
										{
											//#endregion Commerce
										}
										<li className="roc-utlity-nav__menu-item">
											<a
												href={MY_ACCOUNT_WALLET_URL}
												className=" roc-utlity-nav__menu-item-link"
												data-testid="my-account-popover-wallet-link"
											>
												<CreditCardSimpleSVG className={iconClass} />
												{Localizer('Wallet')}
											</a>
										</li>
										{
											//#region Commerce
										}
									</>
								) : null}

								<li className="roc-utlity-nav__menu-item">
									<a
										href={MY_ACCOUNT_SAVED_FOR_LATER_URL}
										className="roc-utlity-nav__menu-item-link"
										data-testid="my-account-popover-saved-for-later-link"
									>
										<BookmarkSVG className={iconClass} />
										{Localizer('SavedForLater')}
									</a>
								</li>

								<li className="roc-utlity-nav__menu-item">
									<a
										href={MY_ACCOUNT_SAVED_CARTS_URL}
										className="roc-utlity-nav__menu-item-link"
										data-testid="my-account-popover-saved-carts-link"
									>
										<BookmarkSVG className={iconClass} />
										{Localizer('SavedCarts')}
									</a>
								</li>

								<li className="roc-utlity-nav__menu-item">
									<a
										href={MY_ACCOUNT_SHOPPING_LISTS_URL}
										className="roc-utlity-nav__menu-item-link"
										data-testid="my-account-popover-shopping-lists-link"
									>
										<ShoppingListSVG className={iconClass} />
										{Localizer('ShoppingLists')}
									</a>
								</li>

								{userData.enableStoreLocation ? (
									<li className="roc-utlity-nav__menu-item">
										<a
											href={MY_ACCOUNT_STORE_URL}
											className="roc-utlity-nav__menu-item-link"
											data-testid="my-account-popover-store-link"
										>
											<HouseSVG className={iconClass} />
											{Localizer('Store')}
										</a>
									</li>
								) : null}

								{
									// #endregion
								}
								{/* Under Review
								<li className="roc-utlity-nav__menu-item">
									<a
										href={MY_ACCOUNT_PERSONAL_DATA_URL}
										className=" roc-utlity-nav__menu-item-link"
										data-testid="my-account-popover-personal-data-link"
									>
										<ShieldUserSVG className={iconClass} />
										{Localizer('HeaderUtilNavPersonalData')}
									</a>
								</li> */}

								{/* #region Commerce */}
								<li className="roc-utlity-nav__menu-item">
									<a
										href={MY_ACCOUNT_SETTINGS_URL}
										className="roc-utlity-nav__menu-item-link"
										data-testid="my-account-popover-settings-link"
									>
										<GearSVG className={iconClass} />
										{Localizer('HeaderUtilNavSettings')}
									</a>
								</li>

								{/* #endregion */}
								<li className="roc-utlity-nav__menu-item">
									{/* #region HCL - changed Logout to ExternalLogout in the line below */}
									<form action={`${AccountUrls.ExternalLogout}`} method="post" id="logoutForm">
										<button
											type="submit"
											className="roc-utlity-nav__menu-item-link"
											data-testid="my-account-popover-logout-link"
										>
											<LockSimpleSVG className={iconClass} />
											{Localizer('HeaderUtilNavLogout')}
										</button>
									</form>
								</li>
							</ul>
						</HeaderUtilNavItemPopover>

						<HeaderUtilNavItemLink
							icon={
								<ClockSVG className={headerUtilsIconClass + ' roc-utlity-nav__menu-item-icon-clock'} />
							}
							// #region HCL
							path="/order-status"
							// #endregion
							text={Localizer('OrderStatus')}
							testId="orders"
						/>
					</>
				) : (
					<>
						{/* #region - HCL - commented out */}
						{/* 
						 <HeaderUtilNavItemLink
							icon={<CheckClipboardSVG className={headerUtilsIconClass} />}
							path={AccountUrls.Register}
							text={Localizer('Register')}
							testId="register"
							/>
						  <HeaderUtilNavItemLink
							path={AccountUrls.Login}
							text={Localizer('HeaderLoginText')}
							testId="login"
						/> */}
						{/* #endregion - HCL - commented out */}

						{/* #region - HCL */}
						<HeaderUtilExternalRegisterLink
							path={AccountUrls.Login}
							text={Localizer('Register')}
							testId="register"
						/>
						<HeaderUtilExternalLoginLink
							path={AccountUrls.Login}
							text={Localizer('HeaderLoginText')}
							testId="login"
						/>
						{/* #endregion - HCL */}

						<HeaderUtilNavItemLink
							icon={
								<ClockSVG className={headerUtilsIconClass + ' roc-utlity-nav__menu-item-icon-clock'} />
							}
							path="/order-status"
							text={Localizer('OrderStatus')}
							testId="order-status"
						/>
					</>
				)}
				{
					// #region Commerce
				}
				<OrganizationHeaderMenu userData={userData} />

				<HeaderUtilNavItemLink
					icon={
						<QuickOrderSVG
							className={headerUtilsIconClass + ' roc-utlity-nav__menu-item-icon-small-icon'}
						/>
					}
					path="/quick-order"
					text={Localizer('QuickOrder')}
					testId="quickorder"
				/>
				<HeaderUtilNavItemCart userData={userData} />
				{
					// #endregion
				}
			</HeaderUtilNav>
		</>
	);
}
