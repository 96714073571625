import React from 'react';
import { initReactApp, initReactAppByAttribute } from 'shared/util/ApplicationInit';
import Organization from './Organization';
import OrganizationRemovalNotification from './OrganizationRemovalNotification';

const render = initReactApp(() => <Organization />, 'organization');

render();

// optional, use this to enable hot reloading
if ((module as any).hot) {
	(module as any).hot.accept('./Organization', () => {
		render();
	});
}

/**
 * Perform module setup for user organization removal notification
 */
export function setupOrganizationRemovalNotification() {
	const render = initReactAppByAttribute(
		() => <OrganizationRemovalNotification />,
		'data-roc-organization-user-removal-notification',
	);

	render();

	// optional, use this to enable hot reloading
	if ((module as any).hot) {
		(module as any).hot.accept('./OrganizationRemovalNotification', () => {
			render();
		});
	}
}
