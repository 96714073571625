export default function setupFeaturedPostNotification() {
	const cookieFeaturedPostDismissBtn = document.getElementById('cookieFeaturedPostDismissBtn');
	const cookieFeaturedPostViewBtn = document.getElementById('cookieFeaturedPostViewBtn');
	const featuredPostContainer = document.getElementById('featuredPostContainer');
	const cookieMessageContainer = document.getElementById('cookieMessageContainer');

	const handleFeaturedPostsDismissBtnClick = () => {
		let postId = '';
		if (
			cookieFeaturedPostDismissBtn &&
			cookieFeaturedPostDismissBtn.dataset &&
			cookieFeaturedPostDismissBtn.dataset.postid
		) {
			postId = cookieFeaturedPostDismissBtn.dataset.postid;
		}

		const d = new Date();
		// expire in one year
		d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
		const expires = 'expires=' + d.toUTCString();
		document.cookie = 'FeaturedPostNotificationDismissed' + postId + '=yes;' + expires + ';path=/';
		if (cookieFeaturedPostDismissBtn !== null) {
			cookieFeaturedPostDismissBtn.removeEventListener('click', handleFeaturedPostsDismissBtnClick);
			if (featuredPostContainer !== null) {
				featuredPostContainer.remove();
				if (cookieMessageContainer !== null) {
					const positionCheck = cookieMessageContainer.style.bottom;
					if (positionCheck === '90px') {
						if (cookieMessageContainer !== null) {
							cookieMessageContainer.style.bottom = '15px';
						}
					}
				}
			}
		}
	};

	if (cookieFeaturedPostDismissBtn !== null && cookieFeaturedPostViewBtn !== null) {
		cookieFeaturedPostDismissBtn.addEventListener('click', handleFeaturedPostsDismissBtnClick);
		cookieFeaturedPostViewBtn.addEventListener('click', handleFeaturedPostsDismissBtnClick);
	}
}
