import React from 'react';
import { SVGProps } from './SVGProps';

/**
 * Quick Order SVG
 *
 * @returns
 */
function QuickOrderSVG({ className, ...rest }: SVGProps) {
	return (
		<svg viewBox="0 0 24 24" className={'roc-icon ' + className} {...rest}>
			<path d="M15.25,1 C16.3550942,1 17.25,1.8955243 17.25,3 L17.25,3 L18.25,3 C19.8471317,3 21.1536217,4.24944951 21.2449052,5.82378395 L21.25,6 L21.25,20 C21.25,21.5971317 20.0005505,22.9036217 18.4262161,22.9949052 L18.25,23 L6.25,23 C4.65286828,23 3.34637835,21.7505505 3.25509479,20.1762161 L3.25,20 L3.25,18 C3.25,17.4477153 3.69771525,17 4.25,17 C4.76283584,17 5.18550716,17.3860402 5.24327227,17.8833789 L5.25,18 L5.25,20 C5.25,20.512307 5.63653124,20.9354316 6.1334841,20.9932642 L6.25,21 L18.25,21 C18.762307,21 19.1854316,20.6134688 19.2432642,20.1165159 L19.25,20 L19.25,6 C19.25,5.48769298 18.8634688,5.06456839 18.3665159,5.00673583 L18.25,5 L17.25,5 C17.25,6.1044757 16.3550942,7 15.25,7 L15.25,7 L9.25,7 C8.14571525,7 7.25,6.10428475 7.25,5 L7.25,5 L6.25,5 C5.73769298,5 5.31456839,5.38653124 5.25673583,5.8834841 L5.25,6 L5.25,8 C5.25,8.55228475 4.80228475,9 4.25,9 C3.73716416,9 3.31449284,8.61395981 3.25672773,8.11662113 L3.25,8 L3.25,6 C3.25,4.40286828 4.49944951,3.09637835 6.07378395,3.00509479 L6.25,3 L7.25,3 C7.25,1.89571525 8.14571525,1 9.25,1 L9.25,1 Z M12.3165715,8.90269199 C12.6848051,9.25525604 12.7248562,9.82175044 12.4284295,10.2205784 L12.347308,10.3165715 L6.72230801,16.1915715 C6.35206757,16.5782671 5.75021687,16.6006395 5.35302472,16.2626683 L5.26519656,16.1782801 L2.26519656,12.9282801 C1.8905928,12.5224594 1.91589916,11.8898003 2.3217199,11.5151966 C2.69632365,11.1694085 3.26420976,11.1643722 3.64399627,11.4848325 L3.73480344,11.5717199 L6.013,14.04 L10.902692,8.9334285 C11.2846364,8.5345088 11.9176518,8.5207476 12.3165715,8.90269199 Z M15.25,3 L9.25,3 L9.25,5 L15.25,5 L15.25,3 Z"></path>
		</svg>
	);
}

QuickOrderSVG.defaultProps = { className: '' };

export default QuickOrderSVG;
