import { tns } from 'tiny-slider/src/tiny-slider';

/* 
***

This carousel will only work on devices less than 991px only. 
To apply this carousel on any HTML add these attributes on HTML parent/ container node.

1. data-carousel-type="mobile-carousel" (This one is the main attribute, if it's not there, carousel won't work)
2. data-mobile-items="1" (Number of Items to be displayed on Mobile)
3. data-tablet-items="2" (Number of Items to be displayed on Tablet)
4. data-desktop-item="3" (Number of Items to be displayed on Web)
5. data-edge-padding="25" (If present it will show previous and next items on mobile with center item focued)

***
*/

export default function setupCarousel() {
	const carouselContainer = document.querySelectorAll<HTMLElement>('[data-carousel-type="mobile-carousel"]');

	carouselContainer.forEach((_value, i, array) => {
		const carouselContainerClass = `.${array[i].classList[0]}`;
		const desktopItems = isNaN(Number(array[i].dataset.desktopItem)) ? 3 : Number(array[i].dataset.desktopItem);
		const tabletItems = isNaN(Number(array[i].dataset.tabletItems)) ? 2 : Number(array[i].dataset.tabletItems);
		const mobileItems = isNaN(Number(array[i].dataset.mobileItems)) ? 1 : Number(array[i].dataset.mobileItems);
		const edgePadding = isNaN(Number(array[i].dataset.edgePadding)) ? 0 : Number(array[i].dataset.edgePadding);
		const showControls = array[i].dataset.hasControls === 'false' ? false : true;
		const disableSlider = array[i].dataset.disableSlider === 'false' ? false : true;

		tns({
			container: carouselContainerClass,
			items: mobileItems,
			autoplay: true,
			autoplayButtonOutput: false,
			touch: true,
			loop: true,
			controls: showControls,
			navPosition: 'bottom',
			edgePadding: edgePadding,
			gutter: 16,
			responsive: {
				767: {
					items: tabletItems,
				},
				992: {
					items: desktopItems,
					autoplay: false,
					disable: disableSlider,
				},
			},
		});
	});
}
