import React from 'react';
import { RouteProps } from 'react-router';
import {
	MY_ACCOUNT_ADDRESS_BOOK_CREATE_URL,
	MY_ACCOUNT_ADDRESS_BOOK_EDIT_URL,
	MY_ACCOUNT_ADDRESS_BOOK_URL,
	MY_ACCOUNT_PATH_PREFIX,
} from './routes.roc';
// #region HCL
import { ORGANIZATION_ORDERS_URL } from 'features/commerce/organization/routes';
// #endregion

//#region URLs
export const MY_ACCOUNT_ORDERS_URL = `${MY_ACCOUNT_PATH_PREFIX}/orders`;
export const MY_ACCOUNT_STORE_URL = `${MY_ACCOUNT_PATH_PREFIX}/store`;
export const MY_ACCOUNT_SAVED_FOR_LATER_URL = `${MY_ACCOUNT_PATH_PREFIX}/saved-for-later`;
export const MY_ACCOUNT_SAVED_CARTS_URL = `${MY_ACCOUNT_PATH_PREFIX}/saved-carts`;
export const MY_ACCOUNT_BUY_AGAIN_URL = `${MY_ACCOUNT_PATH_PREFIX}/buy-again`;
export const MY_ACCOUNT_SHOPPING_LISTS_URL = `${MY_ACCOUNT_PATH_PREFIX}/shopping-lists`;
export const MY_ACCOUNT_ORGANIZATION_REVIEW_URL = `${MY_ACCOUNT_PATH_PREFIX}/organization-review`;
export const MY_ACCOUNT_ORGANIZATION_SELECTOR_URL = `${MY_ACCOUNT_PATH_PREFIX}/organization-selector`;
export const MY_ACCOUNT_SHOPPING_LISTS_CREATE_URL = `${MY_ACCOUNT_SHOPPING_LISTS_URL}/create`;
//#endregion URLs

//#region Components

//#region HCL
export const MY_ACCOUNT_USER_SHIPPING_ACCOUNT_URL = `${MY_ACCOUNT_PATH_PREFIX}/shipping-accounts`;
export const MY_ACCOUNT_USER_SHIPPING_ACCOUNT_CREATE_URL = `${MY_ACCOUNT_USER_SHIPPING_ACCOUNT_URL}/create`;
export const MY_ACCOUNT_USER_SHIPPING_ACCOUNT_EDIT_URL = `${MY_ACCOUNT_USER_SHIPPING_ACCOUNT_URL}/edit`;
export const MY_ACCOUNT_REVIEWS_URL = `${MY_ACCOUNT_PATH_PREFIX}/reviews`;
export const MY_ACCOUNT_CUSTOMER_ACCOUNT_APPLICATIONS_URL = `/customer-account-application`;
export const MY_ACCOUNT_CUSTOMER_CREDIT_APPLICATIONS_URL = `https://nuvo.com/customer/gohcl`;
export const MY_ACCOUNT_SHOPPER_APPLICATIONS_URL = `${MY_ACCOUNT_PATH_PREFIX}/shopper-applications`;
export const ORGANIZATION_ORDER_DETAILS = `${ORGANIZATION_ORDERS_URL}/:orderNo`;
//#endregion URLs

// #region HCL
//const OrdersComponent = React.lazy(
//	() => import(/* webpackChunkName: "my-account-orders" */ '../commerce/my-account/orders/OrderListing'),
//);

//const OrderDetailsComponent = React.lazy(
//	() =>
//		import(
//			/* webpackChunkName: "my-account-order-details" */ '../commerce/my-account/orders/MyAccountOrderDetails'
//		),
//);

const OrdersComponent = React.lazy(
	() => import(/* webpackChunkName: "my-account-orders" */ '../commerce/my-account/orders/hcl/Orders'),
);

const OrderDetailsComponent = React.lazy(
	() =>
		import(
			/* webpackChunkName: "my-account-order-details" */ '../commerce/organization/orders/DefaultOrganizationOrderDetails'
		),
);
// #endregion

const StoreComponent = React.lazy(
	() => import(/* webpackChunkName: "my-account-store" */ '../commerce/my-account/store/Store'),
);

const AddressBookComponent = React.lazy(
	// #region HCL
	// () => import(/* webpackChunkName: "my-account-address-book" */ '../address-book/AddressBook'),
	() => import(/* webpackChunkName: "my-account-address-book" */ '../address-book/AddressBook.HCL'),
	// #endregion
);

const AddressBookEditComponent = React.lazy(
	() => import(/* webpackChunkName: "my-account-address-book-edit" */ '../address-book/AddressBookEdit'),
);

const SavedForLaterComponent = React.lazy(
	() =>
		import(
			/* webpackChunkName: "my-account-saved-for-later" */ '../commerce/my-account/saved-for-later/SavedForLaterListingWrapper'
		),
);

const SavedCartsComponent = React.lazy(
	() =>
		import(
			/* webpackChunkName: "my-account-saved-carts" */ '../commerce/my-account/saved-carts/SavedCartsListingWrapper'
		),
);

const BuyAgainComponent = React.lazy(
	() => import(/* webpackChunkName: "buy-again" */ '../commerce/my-account/buy-again/BuyAgain'),
);

const AccountOverviewComponent = React.lazy(
	() =>
		import(
			/* webpackChunkName: "my-account-account-overview" */ '../commerce/my-account/account-overview/AccountOverview'
		),
);

const ShoppingListsComponent = React.lazy(
	() =>
		import(
			/* webpackChunkName: "my-account-shopping-lists" */ '../commerce/my-account/shopping-lists/ShoppingListListing'
		),
);

const ShoppingListsCreateComponent = React.lazy(
	() =>
		import(
			/* webpackChunkName: "my-account-shopping-lists-create" */ '../commerce/my-account/shopping-lists/ShoppingListsCreate'
		),
);

const ShoppingListDetailsComponent = React.lazy(
	() =>
		import(
			/* webpackChunkName: "my-account-shopping-list-details" */ '../commerce/my-account/shopping-lists/MyAccountShoppingListDetails'
		),
);

const OrganizationReviewComponent = React.lazy(
	() =>
		import(
			/* webpackChunkName: "my-account-organization-approval" */ '../commerce/my-account/organization/OrganizationReview'
		),
);

const OrganizationSelectorComponent = React.lazy(
	() =>
		import(
			/* webpackChunkName: "my-account-organization-selector" */ '../commerce/my-account/organization/OrganizationSelector'
		),
);
//#region HCL
const UserShippingAccountsComponent = React.lazy(
	() =>
		import(
			/* webpackChunkName: "my-account-address-book" */ '../commerce/user-shipping-account/UserShippingAccounts'
		),
);

const ReviewsComponent = React.lazy(
	() => import(/* webpackChunkName: "my-account-reviews" */ '../hcl/my-account/reviews/ReviewsListing'),
);

const CustomerAccountApplicationsComponent = React.lazy(
	() =>
		import(
			/* webpackChunkName: "my-account-customer-account-applications" */ '../hcl/my-account/account-applications/AccountApplicationsListing'
		),
);

const CustomerAccountApplicationsEditComponent = React.lazy(
	() =>
		import(
			/* webpackChunkName: "my-account-customer-account-applications-create" */ '../hcl/my-account/account-applications/MyAccountApplicationLoader'
		),
);

const CustomerCreditApplicationsComponent = React.lazy(
	() =>
		import(
			/* webpackChunkName: "my-account-customer-account-applications" */ '../hcl/my-account/credit-applications/CreditApplicationsListing'
		),
);

const CustomerCreditApplicationsEditComponent = React.lazy(
	() =>
		import(
			/* webpackChunkName: "my-account-customer-account-applications-create" */ '../hcl/my-account/credit-applications/MyAccountCreditApplicationLoader'
		),
);

const ShopperApplicationsComponent = React.lazy(
	() =>
		import(
			/* webpackChunkName: "my-account-shopper-applications" */ '../hcl/my-account/shopper-applications/ShopperApplicationsListing'
		),
);

// const UserShippingAccountsEditComponent = React.lazy(() =>
// 	import(
// 		/* webpackChunkName: "my-account-address-book-edit" */ '../commerce/user-shipping-account/UserShippingAccountsEdit'
// 	),
// );
//#endregion

//#endregion Components

//#region Routes
const ordersRoute = {
	exact: true,
	path: MY_ACCOUNT_ORDERS_URL,
	component: OrdersComponent,
};

const orderDetailsRoute = {
	exact: true,
	path: `${MY_ACCOUNT_ORDERS_URL}/:orderNo`,
	component: OrderDetailsComponent,
};

const storeRoute = {
	exact: true,
	path: MY_ACCOUNT_STORE_URL,
	component: StoreComponent,
};

const addressBookRoute = {
	exact: true,
	path: MY_ACCOUNT_ADDRESS_BOOK_URL,
	component: AddressBookComponent,
};

const addressBookCreateRoute = {
	exact: true,
	path: MY_ACCOUNT_ADDRESS_BOOK_CREATE_URL,
	component: AddressBookEditComponent,
};

const addressBookEditRoute = {
	exact: true,
	path: `${MY_ACCOUNT_ADDRESS_BOOK_EDIT_URL}/:addressId`,
	component: AddressBookEditComponent,
};

const savedForLaterRoute = {
	exact: true,
	path: MY_ACCOUNT_SAVED_FOR_LATER_URL,
	component: SavedForLaterComponent,
};

const savedCartsRoute = {
	exect: true,
	path: MY_ACCOUNT_SAVED_CARTS_URL,
	component: SavedCartsComponent,
};

const buyAgainRoute = {
	exact: true,
	path: MY_ACCOUNT_BUY_AGAIN_URL,
	component: BuyAgainComponent,
};

const shoppingListsRoute = {
	exact: true,
	path: MY_ACCOUNT_SHOPPING_LISTS_URL,
	component: ShoppingListsComponent,
};

const shoppingListsCreateRoute = {
	exact: true,
	path: MY_ACCOUNT_SHOPPING_LISTS_CREATE_URL,
	component: ShoppingListsCreateComponent,
};

const shoppingListDetailsRoute = {
	exact: true,
	path: `${MY_ACCOUNT_SHOPPING_LISTS_URL}/:shoppingListId`,
	component: ShoppingListDetailsComponent,
};

//#region HCL
const userShippingAccountsRoute = {
	exact: true,
	path: MY_ACCOUNT_USER_SHIPPING_ACCOUNT_URL,
	component: UserShippingAccountsComponent,
};

const reviewsRoute = {
	exact: true,
	path: MY_ACCOUNT_REVIEWS_URL,
	component: ReviewsComponent,
};

const customerAccountApplicationsRoute = {
	exact: true,
	path: MY_ACCOUNT_CUSTOMER_ACCOUNT_APPLICATIONS_URL,
	component: CustomerAccountApplicationsComponent,
};

const customerAccountApplicationsEditRoute = {
	exact: true,
	path: `${MY_ACCOUNT_CUSTOMER_ACCOUNT_APPLICATIONS_URL}/:accountApplicationId`,
	component: CustomerAccountApplicationsEditComponent,
};

const customerCreditApplicationsRoute = {
	exact: true,
	path: MY_ACCOUNT_CUSTOMER_CREDIT_APPLICATIONS_URL,
	component: CustomerCreditApplicationsComponent,
};

const customerCreditApplicationsEditRoute = {
	exact: true,
	path: `${MY_ACCOUNT_CUSTOMER_CREDIT_APPLICATIONS_URL}/:creditApplicationId`,
	component: CustomerCreditApplicationsEditComponent,
};

const shopperApplicationsRoute = {
	exact: true,
	path: MY_ACCOUNT_SHOPPER_APPLICATIONS_URL,
	component: ShopperApplicationsComponent,
};

// const userShippingAccountsCreateRoute = {
// 	exact: true,
// 	path: MY_ACCOUNT_USER_SHIPPING_ACCOUNT_CREATE_URL,
// 	component: UserShippingAccountsEditComponent,
// };

// const userShippingAccountsEditRoute = {
// 	exact: true,
// 	path: `${MY_ACCOUNT_USER_SHIPPING_ACCOUNT_EDIT_URL}/:shippingAccountId`,
// 	component: UserShippingAccountsEditComponent,
// };
//#endregion

const defaultRoute = {
	exact: false,
	path: '*',
	component: AccountOverviewComponent,
};

const organizationReviewRoute = {
	exact: true,
	path: MY_ACCOUNT_ORGANIZATION_REVIEW_URL,
	component: OrganizationReviewComponent,
};

const organizationSelectorRoute = {
	exact: true,
	path: MY_ACCOUNT_ORGANIZATION_SELECTOR_URL,
	component: OrganizationSelectorComponent,
};
//#endregion Routes

export const commerceRoutes: RouteProps[] = [
	ordersRoute,
	orderDetailsRoute,
	storeRoute,
	addressBookRoute,
	addressBookCreateRoute,
	addressBookEditRoute,
	savedForLaterRoute,
	savedCartsRoute,
	buyAgainRoute,
	shoppingListsRoute,
	shoppingListsCreateRoute,
	shoppingListDetailsRoute,
	organizationReviewRoute,
	organizationSelectorRoute,
	//#region HCL
	reviewsRoute,
	userShippingAccountsRoute,
	customerAccountApplicationsRoute,
	customerAccountApplicationsEditRoute,
	customerCreditApplicationsRoute,
	customerCreditApplicationsEditRoute,
	shopperApplicationsRoute,
	// userShippingAccountsCreateRoute,
	// userShippingAccountsEditRoute,
	//#endregion
	defaultRoute,
];
