import React from 'react';
import ProductPricing from 'shared/components/commerce/ProductPricing';
import { dispatchRocEvent } from 'shared/hooks/useRocEventHandler';
import { toKebabCase } from '../../../util/StringUtil';
import { Banner } from '../../types/banners';

interface ProductBannerProps {
	banner: Banner;
}

export function ProductBanner(props: ProductBannerProps) {
	const { banner } = props;

	const product = banner?.product;
	const displayPrice = banner?.price;
	//#region HCL
	const mainImageUrl = banner?.imageDto?.url;
	//#endregion
	const mainImageAltText = banner?.altTag;
	let customUrl: string = '#';
	if (product?.url) {
		customUrl = product.url;
	}

	return (
		<div className="roc-product-list__item">
			<a
				href={customUrl}
				className="roc-product-list__link"
				onClick={() =>
					product &&
					dispatchRocEvent('product-clicked', {
						productId: product.id,
						url: customUrl,
						productName: product.name ?? '',
						productGroupId: product.productGroupId ?? null,
					})
				}
			>
				{mainImageUrl !== null && mainImageAltText != null ? (
					<div className="roc-product-list__image-wrapper">
						{/* #region HCL */}
						<img
							src={mainImageUrl}
							data-src={mainImageUrl}
							alt={mainImageAltText}
							className="roc-product-list__image tns-lazy-img"
						/>
						{/* #endregion */}
					</div>
				) : null}
				{product && product.name ? (
					<h3 className="roc-product-list__title" data-testid={`rvp-header-${toKebabCase(product.name)}`}>
						{product.name}
					</h3>
				) : null}

				{displayPrice ? <ProductPricing displayPrice={displayPrice} className="roc-product-list" /> : null}
			</a>
		</div>
	);
}
