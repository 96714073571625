/* eslint-disable jsx-a11y/no-onchange */
import Axios, { AxiosError } from 'axios';
import React, { useState } from 'react';
import { Localizer } from 'services/Localizer';
import { UserData } from 'services/UserData';
import ClockSVG from 'shared/components/svg/ClockSVG';
import OrganizationSimpleSVG from 'shared/components/svg/commerce/OrganizationSimpleSVG';
import UsersSVG from 'shared/components/svg/commerce/UsersSVG';
import MapPinSVG from 'shared/components/svg/MapPinSVG';
import InlineAlert from 'shared/edit/InlineAlert';
import { HeaderUtilNavItemPopover } from '../../header/HeaderUtilNav';
import { updateUserDefaultOrganization } from '../my-account/organization/OrganizationSelector';
import { ORGANIZATION_ORDERS_URL, ORGANIZATION_SHIPTOS_URL, ORGANIZATION_USERS_URL } from './routes';

export const headerUtilsIconClass = 'roc-utlity-nav__menu-item-icon';

interface OrganizationHeaderMenuProps {
	userData: UserData | null;
}

/**
 * Component that renders the organization header menu
 *
 * @export
 * @param {HeaderUtilNavProps} props
 * @returns JSX.Element
 */
export default function OrganizationHeaderMenu(props: OrganizationHeaderMenuProps) {
	const [errorMessage, setErrorMessage] = useState<string>();
	const { userData } = props;
	const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
	const iconClass = 'roc-utlity-nav__menu-item-link-icon';

	const isOrganizationUser =
		userData && userData.summary && userData.summary.isSignedIn && userData.summary.organization;

	const hasSingleOrganization =
		isOrganizationUser && userData?.summary?.userOrganizations && userData.summary.userOrganizations.length == 1;

	/**
	 * set the current user default organization
	 */
	const setUserDefaultOrganization = async (organizationId?: string): Promise<void> => {
		try {
			await updateUserDefaultOrganization(organizationId);
			window.location.reload();
		} catch (error) {
			if (Axios.isCancel(error)) {
				// if the component was unmounted, there's nothing to do
				return;
			}
			const axiosError = error as AxiosError;

			if (axiosError && axiosError.response && axiosError.response.data && axiosError.response.data.message) {
				setErrorMessage(axiosError.response.data.message);
			}
		}
	};

	return (
		<>
			{isOrganizationUser && (
				<HeaderUtilNavItemPopover
					isOpen={popoverOpen}
					openPopover={() => setPopoverOpen(true)}
					closePopover={() => setPopoverOpen(false)}
					icon={<OrganizationSimpleSVG className={headerUtilsIconClass} />}
					text={Localizer('OrganizationHeaderText')}
					title={hasSingleOrganization ? userData?.summary?.organization?.organizationName : undefined}
					size="xs"
					testId="organization"
				>
					<ul className="roc-utlity-nav__menu">
						{/* 
						ROC-3667 temporary hidden
						<li className="roc-utlity-nav__menu-item">
							<a
								href={ORGANIZATION_OVERVIEW_URL}
								className="roc-utlity-nav__menu-item-link"
								data-testid="organization-popover-account-overview-link"
							>
								<OrganizationSimpleSVG className={iconClass} />
								{Localizer('OrganizationOverviewText')}
							</a>
						</li> */}

						{hasSingleOrganization ? null : (
							<li className="roc-utlity-nav__menu-item">
								{errorMessage ? <InlineAlert theme="danger" message={errorMessage} /> : null}
								<select
									className="roc-form-input__input"
									value={userData?.summary?.organization?.id ?? ''}
									onChange={(event) => setUserDefaultOrganization(event.target.value)}
									data-testid="organization-selector-dropdown"
								>
									{userData?.summary?.userOrganizations?.map((organization, index) => (
										<option
											key={index}
											value={organization.value}
											data-testid={`organization-selection-${index}`}
										>
											{organization.label}
										</option>
									))}
								</select>
							</li>
						)}
						<li className="roc-utlity-nav__menu-item">
							<a
								href={ORGANIZATION_ORDERS_URL}
								className="roc-utlity-nav__menu-item-link"
								data-testid="organization-popover-orders-link"
							>
								<ClockSVG className={iconClass} />
								{Localizer('OrganizationOrders')}
							</a>
						</li>

						<li className="roc-utlity-nav__menu-item">
							<a
								href={ORGANIZATION_SHIPTOS_URL}
								className="roc-utlity-nav__menu-item-link"
								data-testid="organization-popover-addresses-link"
							>
								<MapPinSVG className={iconClass} />
								{Localizer('ShipTosText')}
							</a>
						</li>
						{userData?.summary?.permissions?.canSeeOrganizationUsers && (
							<li className="roc-utlity-nav__menu-item">
								<a
									href={ORGANIZATION_USERS_URL}
									className="roc-utlity-nav__menu-item-link"
									data-testid="organization-popover-users-link"
								>
									<UsersSVG className={iconClass} />
									{Localizer('OrgUsers')}
								</a>
							</li>
						)}
					</ul>
				</HeaderUtilNavItemPopover>
			)}
		</>
	);
}
