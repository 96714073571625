import React, { useState, useEffect, useRef } from 'react';
import { Localizer } from 'services/Localizer';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import { useLocation, useHistory } from 'react-router';
import { ServerData } from 'services/ServerData';
import MenuSVG from 'shared/components/svg/MenuSVG';
import { ORGANIZATION_USERS_URL, ORGANIZATION_SHIPTOS_URL, ORGANIZATION_ORDERS_URL } from './routes';
import ClockSVG from 'shared/components/svg/ClockSVG';
import UsersSVG from 'shared/components/svg/commerce/UsersSVG';
import MapPinSVG from 'shared/components/svg/MapPinSVG';
import { getUserData, UserData } from 'services/UserData';
import AccountUrls from 'shared/util/AccountUrls';

/**
 * Navigation component for the Organization SPA
 */
export default function Nav() {
	const sidebarEl = useRef<HTMLDivElement>(null);
	const location = useLocation();
	const history = useHistory();

	const [isNavOpen, setIsNavOpen] = useState(false);
	const [isOrganizationUser, setIsOrganizationUser] = useState(false);
	const [userData, setUserData] = useState<UserData>();

	/**
	 * Handle when the user clicks the toggle nav button.
	 */
	const handleToggleNavClick = () => {
		setIsNavOpen(!isNavOpen);
	};

	if (
		!history.location.pathname.startsWith(AccountUrls.OrganizationReview) &&
		userData &&
		userData.summary &&
		userData.summary.organizationInvites &&
		userData.summary.organizationInvites.length > 0
	) {
		history.push(AccountUrls.OrganizationReview);
	} else if (
		!history.location.pathname.startsWith(AccountUrls.OrganizationSelector) &&
		userData &&
		userData.summary &&
		userData.summary.userOrganizations &&
		userData.summary.userOrganizations.length > 1 &&
		userData.summary.organization == null
	) {
		history.push(AccountUrls.OrganizationSelector);
	}

	let pageTitle = '';
	let ordersActive = false;
	let usersActive = false;
	let shiptosActive = false;

	if (location.pathname.startsWith(ORGANIZATION_ORDERS_URL)) {
		pageTitle = Localizer('Orders');
		ordersActive = true;
	} else if (location.pathname.startsWith(ORGANIZATION_USERS_URL)) {
		pageTitle = Localizer('OrgUsers');
		usersActive = true;
	} else if (location.pathname.startsWith(ORGANIZATION_SHIPTOS_URL)) {
		pageTitle = Localizer('ShipTosText');
		shiptosActive = true;
	} else {
		// default page
		pageTitle = Localizer('OrganizationOverviewText');
	}

	document.title = pageTitle + ServerData.PageTitleSuffix;

	const handleClickOutside = (e) => {
		if (sidebarEl && sidebarEl.current && !sidebarEl.current.contains(e.target)) {
			setIsNavOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		history.listen(() => {
			setIsNavOpen(false);
		});

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [showUserNavigation, setShowUserNavigation] = useState<boolean>(true);
	useEffect(() => {
		(async () => {
			try {
				const data = (await getUserData()).data;

				const isOrganizationUser =
					(data && data.summary && data.summary.isSignedIn && data.summary.organization != null) ?? false;
				setIsOrganizationUser(isOrganizationUser);

				const canSeeOrganizationUsers =
					(isOrganizationUser && data?.summary?.permissions?.canSeeOrganizationUsers) ?? false;
				setShowUserNavigation(canSeeOrganizationUsers);
				setUserData(data);
			} catch (error) {
				console.error('Unable to load user data.', error);
			}
		})();
	}, []);

	if (!isOrganizationUser) {
		return null;
	}

	const iconClass = 'roc-manage-menu__icon';

	return (
		<div className="roc-manage-layout__sidebar" ref={sidebarEl}>
			<nav
				aria-labelledby="nav-manage"
				className={classnames('roc-manage-layout__nav-wrapper', {
					'roc-manage-layout__nav-wrapper--open': isNavOpen,
				})}
			>
				<h2 id="nav-manage" className="roc-visually-hidden">
					{Localizer('ManagePageNavigation')}
				</h2>
				<button
					type="button"
					className="roc-manage-layout__toggle"
					data-testid="sidenav-toggle-navigation-btn"
					onClick={handleToggleNavClick}
				>
					<MenuSVG className="roc-manage-menu__icon" />
					{isNavOpen ? (
						<span>{Localizer('CloseNavigation')}</span>
					) : (
						<span>{Localizer('OpenNavigation')}</span>
					)}
				</button>
				<ul className="roc-manage-menu">
					{/* ROC-3667 temporary hidden
					<li className={classnames('roc-manage-menu__item', { active: organizationOverviewActive })}>
						<NavLink
							to={ORGANIZATION_OVERVIEW_URL}
							className="roc-manage-menu__link"
							data-testid="org-sidenav-overview-btn"
						>
							<OrganizationSimpleSVG className={iconClass} />
							<span>{Localizer('OrganizationOverviewText')}</span>
						</NavLink>
					</li>
					 */}
					<li className={classnames('roc-manage-menu__item', { active: ordersActive })}>
						<NavLink
							to={ORGANIZATION_ORDERS_URL}
							className="roc-manage-menu__link"
							data-testid="org-sidenav-orders-btn"
						>
							<ClockSVG className={iconClass} />
							<span>{Localizer('Orders')}</span>
						</NavLink>
					</li>
					<li className={classnames('roc-manage-menu__item', { active: shiptosActive })}>
						<NavLink
							to={ORGANIZATION_SHIPTOS_URL}
							className="roc-manage-menu__link"
							data-testid="org-sidenav-shiptos-btn"
						>
							<MapPinSVG className={iconClass} />
							<span>{Localizer('ShipTosText')}</span>
						</NavLink>
					</li>
					{showUserNavigation ? (
						<li className={classnames('roc-manage-menu__item', { active: usersActive })}>
							<NavLink
								to={ORGANIZATION_USERS_URL}
								className="roc-manage-menu__link"
								data-testid="org-sidenav-users-btn"
							>
								<UsersSVG className={iconClass} />
								<span>{Localizer('OrgUsers')}</span>
							</NavLink>
						</li>
					) : null}
				</ul>
			</nav>
		</div>
	);
}
