import React from 'react';
import { initReactApp } from 'shared/util/ApplicationInit';
import { Banner } from '../../types/banners';
import { ProductBanner } from './ProductBanner';

export default function setupProductBanners(id: string, banner: Banner) {
	const render = initReactApp(() => <ProductBanner banner={banner} />, id);

	render();

	// optional, use this to enable hot reloading
	if ((module as any).hot) {
		(module as any).hot.accept('./ProductBanner', () => {
			render();
		});
	}
}
