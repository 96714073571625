import Axios, { AxiosError, CancelTokenSource } from 'axios';
import { UPDATE_USER_DEFAULT_ORGANIZATION_URL } from 'features/commerce/organization/routes';
import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router';
import AxiosHelper from 'services/AxiosHelper';
import { Localizer } from 'services/Localizer';
import { getUserData, OrganizationDto, UserData } from 'services/UserData';
import OrganizationSimpleSVG from 'shared/components/svg/commerce/OrganizationSimpleSVG';
import InlineAlert from 'shared/edit/InlineAlert';
import AccountUrls from 'shared/util/AccountUrls';
import { toKebabCase } from 'util/StringUtil';

/**
 * Function to update current user default organization.
 */
export async function updateUserDefaultOrganization(organizationId?: string) {
	const requestToken: CancelTokenSource = Axios.CancelToken.source();
	await AxiosHelper.post(
		`/ajax${UPDATE_USER_DEFAULT_ORGANIZATION_URL}`,
		{
			organizationId: organizationId,
		},
		{
			cancelToken: requestToken.token,
		},
	);
}

/**
 * Component displayed to user to select the organization that he will be shopping for.
 */
function OrganizationSelector() {
	const [errorMessage, setErrorMessage] = useState<string>();
	const [userData, setUserData] = useState<UserData>();
	const history = useHistory();

	useEffect(() => {
		(async () => {
			try {
				const data = (await getUserData()).data;
				setUserData(data);
			} catch (error) {
				console.error('Unable to load user data.', error);
			}
		})();
	}, []);

	// if a user tries accessing the organization selector and the server middeleware
	// has not been triggered and the user has not multiple organizations
	// we need to redirect him to my-account page
	useEffect(() => {
		if (
			userData &&
			userData.summary &&
			(userData.summary?.userOrganizations == null ||
				userData.summary?.organization ||
				userData.summary?.userOrganizations.length == 1) &&
			history.location.pathname.startsWith(AccountUrls.OrganizationSelector)
		) {
			window.location.href = AccountUrls.BaseUrl;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData]);

	/**
	 * set the current user default organization
	 */
	const setUserDefaultOrganization = async (organizationId?: string): Promise<void> => {
		try {
			await updateUserDefaultOrganization(organizationId);
			window.location.href = AccountUrls.BaseUrl;
		} catch (error) {
			if (Axios.isCancel(error)) {
				// if the component was unmounted, there's nothing to do
				return;
			}
			const axiosError = error as AxiosError;

			if (axiosError && axiosError.response && axiosError.response.data && axiosError.response.data.message) {
				setErrorMessage(axiosError.response.data.message);
			}
		}
	};

	if (!userData?.summary?.userOrganizations) {
		return null;
	}

	return (
		<>
			<h1 className="roc-manage-layout__heading">
				<OrganizationSimpleSVG className="roc-manage-layout__heading-icon" />{' '}
				<span className="roc-manage-layout__heading-text">{Localizer('OrganizationSelectorTitle')}</span>
			</h1>

			<div className="roc-manage-layout__card-group">
				<div className="roc-manage-layout__card-group-item">
					{errorMessage ? <InlineAlert theme="danger" message={errorMessage} /> : null}

					<DefaultOrganizationSelector
						organizations={userData.summary.userOrganizations}
						currentOrganizationId={userData.summary.organization?.id}
						selectDefaultOrganization={setUserDefaultOrganization}
					/>
				</div>
			</div>
		</>
	);
}

/**
 * Default organization selector component props.
 */
interface DefaultOrganizationSelectorProps {
	/**
	 * Current user organizations list.
	 */
	organizations: OrganizationDto[];

	/**
	 * Current selected organization.
	 */
	currentOrganizationId?: string;

	/**
	 * Sets the user default organization.
	 */
	selectDefaultOrganization: (defaultOrganization: string) => Promise<void>;
}

/**
 * Component displayed to user to select default organization.
 */
function DefaultOrganizationSelector(props: DefaultOrganizationSelectorProps) {
	const { currentOrganizationId, organizations, selectDefaultOrganization } = props;
	const [selectedDefaultOrganization, setSelectedDefaultOrganization] = useState<OrganizationDto>();

	useEffect(() => {
		const defaultOrganization = organizations?.find((org) => org.value === currentOrganizationId);
		setSelectedDefaultOrganization(defaultOrganization ?? organizations[0]);
	}, [organizations, currentOrganizationId]);

	return (
		<>
			<div className="roc-manage-layout__card-group-item-body" data-testid="organization-selector-label">
				{Localizer('OrganizationSelectorText')}
			</div>
			<fieldset className="roc-radio-buttons-panel">
				<div className="roc-radio-buttons-panel__container">
					{organizations.map((organization, index) => (
						<OrganizationSelectorOption
							organization={organization}
							setSelectedOrganizationOption={setSelectedDefaultOrganization}
							currentOrganizationId={currentOrganizationId}
							selectedOrganization={selectedDefaultOrganization}
							key={index}
							index={index}
						/>
					))}
				</div>
			</fieldset>
			<div className="roc-manage-layout__card-group-item-footer">
				<div className="roc-manage-layout__actions">
					<button
						type="button"
						data-testid="organization-selector-submit-button"
						onClick={() => {
							selectDefaultOrganization(selectedDefaultOrganization?.value ?? organizations[0].value);
						}}
						className="roc-btn  roc-manage-layout__actions-item"
					>
						{Localizer('Submit')}
					</button>
				</div>
			</div>
		</>
	);
}

/**
 * Single organization selector option props.
 */
interface OrganizationSelectorOptionProps {
	/**
	 * The organization that we will show an option for.
	 */
	organization: OrganizationDto;

	/**
	 * The function to updated the selected organization.
	 */
	setSelectedOrganizationOption: (organization: OrganizationDto) => void;

	/**
	 * The currently seleted organization.
	 */
	selectedOrganization?: OrganizationDto;

	/**
	 * The user current default organization id.
	 */
	currentOrganizationId?: string;

	/**
	 *  unique key for each option.
	 */
	index?: number;
}

/**
 * Markup for individual organization selector option.
 */
function OrganizationSelectorOption(props: OrganizationSelectorOptionProps) {
	const { organization, setSelectedOrganizationOption, selectedOrganization, currentOrganizationId, index } = props;

	const onRadioButtonChanged = () => {
		setSelectedOrganizationOption(organization);
	};

	return (
		<label
			className="roc-radio-buttons-panel__item"
			htmlFor={`organization-option-${organization.value}`}
			key={`organization-option-${organization.value}`}
			data-testid={toKebabCase(organization.label) + '-label'}
		>
			<span className="roc-radio-buttons-panel__item-text">
				<span
					className="roc-radio-buttons-panel__item-label"
					data-testid={`organization-option-label-${index}`}
				>
					{organization.label}{' '}
					{organization.value == currentOrganizationId ? '( ' + Localizer('CurrentText') + ' )' : null}
				</span>
			</span>
			<input
				type="radio"
				name="organizations"
				checked={organization.value === selectedOrganization?.value}
				onChange={onRadioButtonChanged}
				id={`organization-option-${organization.value}`}
				className="roc-radio-buttons-panel__item-input"
				data-testid={`organization-option-input-${index}`}
			/>
			<span className="roc-radio-buttons-panel__item-faux-btn" />
		</label>
	);
}

export default withRouter(OrganizationSelector);
