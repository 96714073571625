import React from 'react';
import UpChevronSVG from 'shared/components/svg/LeftChevronSVG';
import { Localizer } from 'services/Localizer';

/**
 * Represents one breadcrumb item in a list of breadcrumb items that defines the breadcrumb trail
 */
export interface BreadcrumbItem {
	label: string;
	url?: string;
}

interface BreadcrumbProps {
	breadcrumbPath?: BreadcrumbItem[];
	backToPage?: BreadcrumbItem;
	renderPathLink?: (item: BreadcrumbItem) => React.ReactNode;
}

/**
 * Renders a set of links that represent breadcrumbs for the current location.]
 * It can optionally render a "back to page" link when this is set in sessionStorage.
 */
export default function Breadcrumbs(props: BreadcrumbProps) {
	const {
		breadcrumbPath,
		backToPage,
		renderPathLink = (item) => (
			<a href={item.url} className="roc-breadcrumbs-bar__link" data-testid={'breadcrumb-link-' + item.label}>
				{item.label}
			</a>
		),
	} = props;

	return (
		<div className="roc-breadcrumbs-bar">
			{backToPage && backToPage.url && window.history.length > 2 ? (
				<>
					<div className="roc-breadcrumbs-bar__back-icon-wrapper">
						<UpChevronSVG className="roc-breadcrumbs-bar__back-icon" />
					</div>
					<button
						onClick={() => window.history.back()}
						className="roc-breadcrumbs-bar__back-link roc-btn-link"
					>
						{backToPage.label}
					</button>
					<span className="roc-breadcrumbs-bar__spacer">|</span>
				</>
			) : null}
			{breadcrumbPath ? (
				<>
					<a href="/" className="roc-breadcrumbs-bar__link" data-testid="breadcrumb-link-home">
						{Localizer('Home')}
					</a>
					<div className="roc-breadcrumbs-bar__icon-wrapper">/</div>
					{breadcrumbPath.map((entry, i) => (
						<React.Fragment key={i}>
							{renderPathLink(entry)}
							{i + 1 < breadcrumbPath.length ? (
								<div className="roc-breadcrumbs-bar__icon-wrapper">/</div>
							) : null}
						</React.Fragment>
					))}
				</>
			) : null}
		</div>
	);
}
