import React, { useEffect, useRef } from 'react';
import { WithTestId } from 'shared-client/types/test';
import RocPopover, { RocPopoverSize } from 'shared/components/RocPopover';
import DownChevronSVG from 'shared/components/svg/DownChevronSVG';
import UpChevronSVG from 'shared/components/svg/UpChevronSVG';
import UserSVG from 'shared/components/svg/UserSVG';
import { toKebabCase } from 'util/StringUtil';
import { slugify } from 'features/setup/Utils';
import classnames from 'classnames';
import type { Position } from '@reach/popover';
import Spinner from 'shared/components/Spinner';

// #region Commerce
import ShoppingCartSVG from 'shared/components/svg/ShoppingCartSVG';
import { UserData } from '../../services/UserData';
import { Localizer } from 'services/Localizer';
import CheckClipboardSVG from 'shared/components/svg/CheckClipBoard';
// #endregion

interface HeaderUtilNavProps {
	children: React.ReactNode;
}

/**
 * Simple component wrapper for header util nav.
 *
 * @export
 * @param {HeaderUtilNavProps} props
 * @returns
 */
export default function HeaderUtilNav(props: HeaderUtilNavProps) {
	return (
		<ul className="roc-utility-nav" data-testid="header-util-nav">
			{props.children}
		</ul>
	);
}

interface HeaderUtilNavButtonProps {
	onClick: () => void;
	text: string;
	icon?: React.ReactNode;
}

/**
 * Reusable component for buttons inside header util nav.
 *
 * @export
 * @param {HeaderUtilNavButtonProps} props
 * @returns
 */
export function HeaderUtilNavItemButton(props: HeaderUtilNavButtonProps) {
	const { onClick, text, icon } = props;
	return (
		<li className="roc-utility-nav__item">
			<button
				onClick={onClick}
				className="roc-utility-nav__item-link"
				data-testid={`header-${toKebabCase(text)}-link`}
				aria-label={slugify(text)}
			>
				<span className="roc-utlity-nav__menu-item-icon-wrapper">{icon}</span>
				<span className="roc-utlity-nav__menu-item-text">{text}</span>
			</button>
		</li>
	);
}

interface HeaderUtilNavLinkProps extends WithTestId {
	path: string;
	text: string;
	icon?: React.ReactNode;
}

/**
 * Reusable component for links inside header util nav.
 *
 * @export
 * @param {HeaderUtilNavLinkProps} props
 * @returns
 */
export function HeaderUtilNavItemLink(props: HeaderUtilNavLinkProps) {
	const { path, text, icon = <UserSVG className="roc-utlity-nav__menu-item-icon" />, testId } = props;
	return (
		<li className={`roc-utility-nav__item roc-utility-nav__item--${text.replace(/\s+/g, '-').toLowerCase()}`}>
			<a
				href={path}
				className="roc-utility-nav__item-link"
				data-testid={`header-${testId}-link`}
				aria-label={slugify(text)}
			>
				<span className="roc-utlity-nav__menu-item-icon-wrapper">{icon}</span>
				<span className="roc-utlity-nav__menu-item-text">{text}</span>
			</a>
		</li>
	);
}

// #region HCL - Replace the login link with external login
export function HeaderUtilExternalLoginLink(props: HeaderUtilNavLinkProps) {
	const { text, icon = <CheckClipboardSVG className="roc-utlity-nav__menu-item-icon" /> } = props;
	return (
		<li className={`roc-utility-nav__item roc-utility-nav__item--${text.replace(/\s+/g, '-').toLowerCase()}`}>
			<form id="external-login-form" action="/my-account/external-login" method="post">
				<button
					className="roc-utility-nav__item-link"
					type="submit"
					name="provider"
					value="AzureADB2C"
					title={text}
				>
					<span className="roc-utlity-nav__menu-item-icon-wrapper">{icon}</span>
					<span className="roc-utlity-nav__menu-item-text">{text}</span>
				</button>
			</form>
		</li>
	);
}

export function HeaderUtilExternalRegisterLink(props: HeaderUtilNavLinkProps) {
	const { text, icon = <UserSVG className="roc-utlity-nav__menu-item-icon" /> } = props;
	return (
		<li className={`roc-utility-nav__item roc-utility-nav__item--${text.replace(/\s+/g, '-').toLowerCase()}`}>
			<form id="external-login-form" action="/my-account/external-register" method="post">
				<button
					className="roc-utility-nav__item-link"
					type="submit"
					name="provider"
					value="AzureADB2C"
					title={text}
				>
					<span className="roc-utlity-nav__menu-item-icon-wrapper">{icon}</span>
					<span className="roc-utlity-nav__menu-item-text">{text}</span>
				</button>
			</form>
		</li>
	);
}
// #endregion HCL - WIP

// #region Commerce
interface HeaderUtilNavItemCartProps {
	userData: UserData | null;
}

/**
 * Component for rendering the header cart icon
 *
 * @export
 * @returns
 */
export function HeaderUtilNavItemCart(props: HeaderUtilNavItemCartProps) {
	const { userData } = props;

	return (
		<li className="roc-utility-nav__item roc-utility-nav__item--cart">
			<a href="/cart" className="roc-utility-nav__item-link" data-testid="header-cart-link" aria-label="cart">
				<span className="roc-utlity-nav__cart-wrapper">
					<ShoppingCartSVG className="roc-utlity-nav__cart-icon" />
					<span className="roc-utlity-nav__cart-count" data-testid="cart-item-count-header">
						{userData && userData.cartSummary ? userData.cartSummary.items.toString() : '0'}
					</span>
				</span>
				<span className="roc-utlity-nav__menu-item-text">{Localizer('Cart')}</span>
			</a>
		</li>
	);
}
// #endregion

interface HeaderUtilNavPopoverProps extends WithTestId {
	text: string;
	isOpen: boolean;
	openPopover: () => void;
	closePopover: () => void;
	icon?: React.ReactNode;
	title?: string;
	size?: RocPopoverSize;
	children: React.ReactNode;
	className?: string;
}

/**
 * Reusable component for links inside header util nav.
 *
 * @export
 * @param {HeaderUtilNavPopoverProps} props
 * @returns
 */
export function HeaderUtilNavItemPopover(props: HeaderUtilNavPopoverProps) {
	const triggerRef = useRef<HTMLButtonElement | null>(null);
	const popoverWrapperRef = useRef<HTMLDivElement | null>(null);
	const popoverMatchWidthRef = useRef<Position | null>(null);

	const {
		isOpen,
		openPopover,
		closePopover,
		text,
		icon = <UserSVG className="roc-utlity-nav__menu-item-icon" />,
		children,
		title,
		size,
		className,
	} = props;

	useEffect(() => {
		import('@reach/popover').then((module) => {
			popoverMatchWidthRef.current = module.positionMatchWidth;
		});
	}, []);

	useEffect(() => {
		document.addEventListener('mousedown', handleDocumentClick, false);

		return () => {
			document.removeEventListener('mousedown', handleDocumentClick, false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * If you click outside the popoover when its open, close the popover.
	 *
	 * @param {*} e
	 * @returns
	 */
	function handleDocumentClick(e) {
		if (popoverWrapperRef.current === null || triggerRef.current === null) {
			return;
		}

		// If click is inside popover or trigger, return.
		if (
			triggerRef.current === e.target ||
			triggerRef.current.contains(e.target) ||
			popoverWrapperRef.current === e.target ||
			popoverWrapperRef.current.contains(e.target)
		) {
			return;
		}

		closePopover();
	}

	/**
	 * Handles button click and either opens or closes the popover.
	 *
	 */
	function handleClick() {
		if (isOpen) {
			closePopover();
		} else {
			openPopover();
		}
	}

	const parentClass = `roc-utility-nav__item--${className}`;
	const buttonClass = `roc-utility-nav__item-link--${className}`;
	const textClass = `roc-utlity-nav__menu-item-text--${className}`;

	return (
		<React.Suspense fallback={<Spinner />}>
			<li
				className={classnames('roc-utility-nav__item', {
					[parentClass]: className,
				})}
			>
				<button
					ref={triggerRef}
					className={classnames('roc-utility-nav__item-link', { [buttonClass]: className })}
					data-testid={`header-${props.testId}-link`}
					type="button"
					onClick={handleClick}
					aria-label={slugify(text)}
				>
					<span className="roc-utlity-nav__menu-item-icon-wrapper">{icon}</span>
					<span className={classnames('roc-utlity-nav__menu-item-text', { [textClass]: className })}>
						{text}
					</span>

					{isOpen ? (
						<UpChevronSVG className="roc-utlity-nav__menu-item-chevron" />
					) : (
						<DownChevronSVG className="roc-utlity-nav__menu-item-chevron" />
					)}
				</button>

				{isOpen && popoverMatchWidthRef.current && (
					<RocPopover
						innerRef={popoverWrapperRef}
						targetRef={triggerRef}
						position={popoverMatchWidthRef.current}
						size={size}
						title={title}
					>
						{children}
					</RocPopover>
				)}
			</li>
		</React.Suspense>
	);
}
