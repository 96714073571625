import React, { useEffect, useState } from 'react';
import { getUserData, UserAnnouncement } from 'services/UserData';
import InlineAlert from 'shared/edit/InlineAlert';

export interface AnnouncementsProps extends JSX.IntrinsicAttributes {
	/** The name of the page that is rendering announcements */
	rocAnnouncement: string;
}

/**
 * Displays a list of announcements within the view component Roc.Web\Features\Announcements\Components\Announcements\Default.cshtml.
 * In order to properly handle caching, announcements are populated via a call to /ajax/user.
 * @param props
 */
export default function Announcements(props: AnnouncementsProps): JSX.Element | null {
	const [announcements, setAnnouncements] = useState<UserAnnouncement[] | undefined>([]);
	const [isDismissed, setIsDismissed] = useState<boolean>(false);

	useEffect(() => {
		(async () => {
			try {
				const userData = (await getUserData()).data;

				// Get the announcements for a given page.
				// If announcement.pages is an empty array, the announcement targets all pages
				const filteredAnnouncements = userData?.summary?.announcements.filter((a) => {
					return a.pages.length === 0 || a.pages.includes(props.rocAnnouncement);
				});

				setAnnouncements(filteredAnnouncements);
			} catch (error) {
				console.error('Unable to load user data.', error);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (announcements == null || announcements.length === 0 || isDismissed) {
		return null;
	}

	return (
		<div data-testid="announcements-container">
			<InlineAlert onCollapse={setIsDismissed} isCollapsed={isDismissed} theme="warning" title="" message="">
				{announcements?.map((announcement, index) => {
					return (
						<div
							dangerouslySetInnerHTML={{ __html: announcement.message }}
							key={announcement.id}
							data-testid={`announcement-item-${index}`}
						></div>
					);
				})}
			</InlineAlert>
		</div>
	);
}
